

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCheck, faClose, faInfo, faPassport, faWarning } from "@fortawesome/free-solid-svg-icons";
import {styles} from './homeStyles'

const PopUp = ({data,defaultAction}) => {  
    const   {Confirm,Cancel,Accept,Close} = data 
    const DefaultAction = {status:false,message:"",type:"",icon:"",title:""}
    let IconColor = ""
    const HandlerConfirm = () => {
        if(Confirm){
            Confirm()
            return
        }
        defaultAction(DefaultAction)
    }
    const HandlerClose = () => {
        if(Close){
            Close()
            return
        }
        defaultAction(DefaultAction)
    }
    const HandlerCancel = () => {
        if(Cancel){
            Cancel()
            return
        }
        defaultAction(DefaultAction)
    }
    const HandlerAccept = () => {
        if(Accept){
            Accept()
            return
        }
        defaultAction(DefaultAction)
    }
    let title = ""
    let icono = null
    switch(data?.icon){
        case "pass" :  IconColor = "#008101";  title = "success" ;icono=faCheck; break;
        case "error" :  IconColor = "#ff6101";  title = "error" ; icono=faClose; break;
        case "alert" :  IconColor = "#ffbb25";  title = "warning" ; icono=faWarning; break;
        case "info" :  IconColor = "#00aaff"; title = "information" ; icono=faInfo; break;
        default : IconColor = "#ff6101";  title = "error" ; icono=faClose; break;
    }
    const TituloBox = data?.title || title
    const FinalMsg = data?.message.split("<br>")
    return (<>
    <div className="pop-add" >
        <div className="pop-add-content message-pop " style={styles?.msgPop}>
            <div className="pop-top" 
                style={{width:"100%", background:"#fff",display:"flex",flexDirection:"column",alignItems:"center",color:"white",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}>                    
                <div className="" 
                    style={{...styles?.iconPop , background:IconColor}}>
                            <FontAwesomeIcon icon={icono} />
                </div>

                {data?.title != undefined &&                     
                    <span style={styles?.titlePop}  >
                        {TituloBox}
                    </span>  
                }
                <span style={{color:"#777",fontWeight:600,textAlign:"center",margin:"10px",paddingLeft:"20px",paddingRight:"20px"}}>



                    {FinalMsg.map((a,i)=>{
                        return(<span key={"message-pop-"+i}>{a}<br /></span>)
                    })}


                </span>    
            </div>
            <div className="pop-exit-div" style={{margin:"10px"}}> 
                {
                    data?.type?.includes("close") 
                    && 
                    <button 
                        className="popButonClose" 
                        onClick={HandlerClose} 
                        style={styles?.close}
                    >
                        CLOSE
                    </button>
                }

                {
                    data?.type?.includes("cancel") 
                    && 
                    <button  
                        onClick={HandlerCancel} 
                        className="pop-buton-cancel" 
                        style={styles?.cancel}
                    >
                        CANCEL
                    </button>
                }
                {
                    data?.type?.includes("accept") 
                    && 
                    <button  
                        onClick={HandlerAccept} 
                        className="pop-buton-accept" 
                        style={styles?.accept}
                    >
                        ACCEPT
                    </button>
                }
                {
                    data?.type?.includes("confirm") 
                    && 
                    <button  
                        onClick={HandlerConfirm} 
                        className="pop-buton-confirm" 
                        style={styles?.confirm}
                    >
                        CONFIRM
                    </button>
                }
            </div>
        </div>
    </div>
    </>)
}


export const PopUpCookies = ({cookies}) => {  
   const handlerAcceptCookies = () =>{
    window.localStorage.setItem("cookies",true)
    cookies(false)
   }
    return (<>
    <div className="pop-add" >
        <div className="pop-add-content message-pop " 
        style={{padding:10,display:"flex",flexDirection:"column",maxWidth:"80vw",width:500}}>
            <div className="pop-top" 
                style={{width:"100%", background:"#fff",display:"flex",flexDirection:"column",alignItems:"center",color:"white",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}>                    
                <span style={{color:"#777",fontWeight:600,textAlign:"center",margin:"10px",paddingLeft:"20px",paddingRight:"20px"}}>
                    <span key={"message-pop-"}>
                    We use cookies to ensure you have the best possible experience. To find out more about our cookie user please view our 
                    <a href="/privacity" style={{color:"green",textDecoration:"none"}} target="_BLANK"> Privacy Policy</a>
                    </span>
                </span>    
            </div>
            <div className="pop-exit-div" style={{margin:"10px"}}> 
               
                    <button 
                        className="popButonClose" 
                        onClick={handlerAcceptCookies}
                        style={{...styles?.acceptCookies, background:"#aaa"}}
                    >
                        Decline Cookies
                    </button>
                    <button 
                        className="popButonClose" 
                        onClick={handlerAcceptCookies}
                        style={{...styles?.acceptCookies, marginLeft:10}}
                    >
                        Accept Cookies
                    </button>
            </div>
        </div>
    </div>
    </>)
}

export default PopUp;