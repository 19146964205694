import React, {  useEffect, useRef, useState } from "react";
import { faDollar,faPencil} from "@fortawesome/free-solid-svg-icons";
import {DollarFormat,TokenFormat,Helpers} from '../../helpers'
import  "./calculatorAth.css";
import  "./calculatorAth_responsive.css";
import Select from 'react-select';
import Title from "../title";
import Constants from '../../helpers/constants/Constants'
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGAEvent } from '../../App';
const C = new Constants()
const CalculatorAth = (props) =>{
    const {tokens} = props
    //const [FirstToken,setFirstToken] = 
    const [tokenID,setTokenID] = useState('1')
    const [tokenName,setTokenName] = useState(C.DEFAULT_COIN_SUFIJO)
    const [tokenLabel,setTokenLabel] = useState(C.DEFAULT_COIN_NAME)
    const [tokenIcon,setTokenIcon] = useState(C.DEFAULT_COIN_ICON)
    const [tokenATH,setTokenATH] = useState(69045.00) 
    const [tokenPrice,setTokenPrice] = useState(30041.80)
    const [tokenValue,setTokenValue] = useState(0)
    const [result,setResult] = useState(0)
    const [value,setValue] = useState(1000)
    const input_calc = useRef()
    const [defaultV,setDefaultV] = useState([])
    

    const SelectCalculator = () => {
        const options = [];
        
        tokens.filter((a,i)=>i<1000).slice().map((tkn)=>{
            options.push({
                label: `${tkn.name} (${tkn.sufijo})`,
                icon:`${tkn.logo}`,
                price_ath:tkn.ath_price,
                current_value:tkn.current_price,
                sufijo:tkn.sufijo,
                id:tkn.id
            })
        })
        return (<div className="search-calculator">   
            <img src={tokenIcon} height={20} width={20} />
            <Select 
                key={'select-calculator-2522'}
                className="select-calculator"
                isSearchable
                onChange={handleSelector}
                options={options} 
                placeholder={tokenLabel}
            />
             
        </div>)
    }
    const handleSelector = (objeto) =>{
        const _tokens = 1 / objeto.current_value;
        const _tokensValue = _tokens * input_calc.current.value
        const _result = _tokensValue * objeto.price_ath
        setTokenID(objeto.id)
        setTokenName(objeto.sufijo)
        setTokenLabel(objeto.label)
        setTokenIcon(objeto.icon)
        setTokenATH(objeto.price_ath)
        setTokenPrice(objeto.current_value)
        setTokenValue(_tokensValue)
        setResult(_result)
        useGAEvent("Calculator","Se selecciono otro token en el cripto calculator")
    }
    const handlerChange = (e) => {
        const _tokens = 1 / defaultV[0]?.current_price
        const _tokensValue = _tokens * input_calc.current.value
        const _result = _tokensValue * tokenATH

        if(e?.nativeEvent.data === '-'){
            return
        }
        if(tokenID == '1'){
            //console.log("tokenID == '1'")
            if(defaultV[0]?.current_price > 0){
                setTokenValue(_tokensValue)
                setResult(_result)
            }
        } else{
            //console.log("tokenPrice > 0")
            if(tokenPrice > 0){
                setTokenValue((1 / tokenPrice) * input_calc.current.value)
                setResult(((1 / tokenPrice) * input_calc.current.value) * tokenATH)
            }
           
        }
        if(parseInt(input_calc.current.value) < 0){
            setValue(0)
        } else {
            setValue(Math.abs(input_calc.current.value))
        }
    }

    const updateTokenPrice = () =>{
        const defaulToken = tokens.filter((tkn)=>tkn.id == '1')
        if(tokens[0].name == "Bitcoin"){
            setTokenATH(tokens[0].ath_price)
            setTokenPrice(tokens[0].current_price)
        }
        
        console.log(tokens[0].name)
        setDefaultV(defaulToken)
    }
    useEffect(()=>{
        updateTokenPrice()
    },[tokens])
    useEffect(()=>{
        handlerChange()
    },[tokenName,defaultV])

    return(<>
            <div id="comp-calculator" className="comp-calculator">
                <Title title={'Crypto Calculator ATH'} icon={'calc-icon-green'}
                    subtitle={'Find out the ATH of your new investments. Use our calculator to discover the ATH of your chosen cryptocurrency.'}
                    info={"Calculate the estimated value of your investments at their All Time High (ATH). Select the cryptocurrency of your choice, input the value you would like to invest, and our calculator will show you how much that investment would have been worth at its’ ATH."}
                    label={"calculator"}
                />
                <div className="section-calculator">
                <div className="column-calculator calc-search">
                    <span>Name</span>
                    {SelectCalculator()}
                </div>
                <div className="column-calculator calc-usd" >
                    <span>Total USD</span>
                    <div className="calc-i">
                        {new Helpers().Icon(faDollar,'dollar-calculator')}
                        <input type="number" 
                            ref={input_calc}
                            min={0}
                            className="calc-input"
                            onPaste={handlerChange}
                            onChange={handlerChange}
                            value={value}
                            placeholder={value}
                        />
                        {new Helpers().Icon(faPencil,'edit-calculator')}
                    </div>
                    
                </div>

                {
                    isMobile 
                    && 
                    
                    <div className="column-calculator calc-tokens">
                        <span>Tokens</span>
                        {
                            tokenValue <= 0.00000001 && 
                            <span className="calc-token" style={{fontSize:"18px"}}>{TokenFormat(tokenValue)} {tokenName}</span>
                        }
                        {
                            tokenValue >= 10000000 && 
                            <span className="calc-token" style={{fontSize:"18px"}}>{TokenFormat(tokenValue)} {tokenName}</span>
                        }
                        {
                            tokenValue < 10000000 &&  tokenValue > 0.00000001 &&
                            <span className="calc-token">{TokenFormat(tokenValue)} {tokenName}</span>
                        }
                        
                    </div>
                }
                {
                    !isMobile 
                    && 
                    
                    <div className="column-calculator calc-tokens">
                        <span>Tokens</span>
                        <span className="calc-token">{TokenFormat(tokenValue)} {tokenName}</span>
                    </div>
                }
                <div className="column-calculator calc-ath">
                    <span>Price ATH</span>
                    <span className="calc-ath">${DollarFormat(tokenATH)}</span>
                </div>
                <div className="column-calculator calc-total">
                    <span>Value ATH</span>

                        {   isMobile &&
                            tokenValue <= 0.00000001 && 
                            <span className="calc-total" style={{fontSize:"24px"}}>${DollarFormat(result)}</span>
                        }
                        {
                            isMobile &&
                            tokenValue >= 10000000 && 
                            <span className="calc-total" style={{fontSize:"24px"}}>${DollarFormat(result)}</span>
                        }
                        {
                            isMobile &&
                            tokenValue < 10000000 &&  tokenValue > 0.00000001 &&
                            <span className="calc-total">${DollarFormat(result)}</span>
                        }
                        {
                            !isMobile &&
                            <span className="calc-total">${DollarFormat(result)}</span>
                        }
                    
                </div>                    
            </div>
        </div>
    </>)
}

export default CalculatorAth;