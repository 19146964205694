import React, { useEffect, useState } from "react";



export const styles = {

    confirm: {
        background:"#008101",
        border:"none",
        borderRadius:"5px",
        width:"100%",
        color:"white",
        padding:10,
        fontWeight:800,
        margin:10,
        cursor:"pointer"
    },
    accept: {
        background:"#008101",
        border:"none",
        borderRadius:"5px",
        width:"100%",
        color:"white",
        padding:10,
        fontWeight:800,
        margin:10,
        cursor:"pointer"
    },
    cancel: {
        background:"#ff6101",
        border:"none",
        borderRadius:"5px",
        width:"100%",
        color:"white",
        padding:10,
        fontWeight:800,
        margin:10,
        cursor:"pointer"
    },
   close : {
        background:"#ff6101",
        border:"none",
        borderRadius:"5px",
        width:"100%",
        color:"white",
        padding:10,
        fontWeight:800,
        margin:10,
        cursor:"pointer"
    },
    msgPop:{
        minWidth:"350px",
        maxWidth:"50%",
        background:"#ddd",
        padding:"0",
        borderRadius:"10px"
    },
    iconPop:{
        height:"80px",
        width:"80px",
        maxWidth:"80px",
        maxHeight:"80px",
                               
        borderRadius:"50%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontSize:50,
        marginTop:"calc(-20px)"
    },
    titlePop:{
        color:"#555",
        fontSize:25,
        textTransform:"capitalize",
        fontWeight:600,
        marginTop:"5px"
    },
    acceptCookies:{
        background:"green",
        color:"white",
        border:"none",
        borderRadius:"5px",
        padding:"10px",
        paddingLeft:"30px",
        paddingRight:"30px",
        fontSize:16,
        fontWeight:600
    }


}