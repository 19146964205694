import React, { useState,useRef, useEffect } from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import {Contexto} from '../../../pages/test/Test'
import {Global} from '../../../App'
import {Error,Icon,Intervalo,Consola,Loading} from './Helpers'
import { isMobile } from "react-device-detect";
import { useGAEvent } from '../../../App';
//este componente necesita de una funcion para setear el favorito
//este favorito podria referirse a el id del elemento
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


export const Wallet = ({tkn,popup}) =>{
    //las props ctx refieren al contexto y el tkn refiere al token cargado
    const ref_input = useRef()
    const [editable,setEditable] = useState(false)
    const [expanded,setExpanded] = useState(false)
    const {
        id,name,sufijo,cantidad,ath_price,ath_date,
        logo,grafica_7d,current_price,rank_id
    } = tkn
    const CurrentYear = new Date().getFullYear()
    const tooltipstyle = { 
        backgroundColor: "green", 
        color: "#fff",
        width:"150px",
        minWidth:"150px",
        maxWidth:"50vw",
        zIndex:10000,
        fontSize:"14px",
        borderRadius:"10px",
        opacity:1,
        textTransform:"none",
        fontWeigth:600 
    }
    return (
        <Global.Consumer>
             {({handlerFavorito}) => (
            <Contexto.Consumer>
                
                {({TypePrice,IsLogged,Borrar,BorrarPop,onChangeValue,onChangeValuePop})=>{

                    const handlerEdit = () =>{
                        if(editable){
                            //save
                            if(popup){
                                
                                onChangeValuePop(id,ref_input.current.value)
                            }else{
                                
                                onChangeValue(id,ref_input.current.value)
                            }
                        } else {
                            //make editable
                        }
                        setEditable(!editable)
                    }

                    const Favorito = ()=>{
                        useGAEvent("SwapAth","Se utilizo el icono estrela para usar token en el swap-ath")
                        handlerFavorito(name,sufijo,cantidad,logo)
                        handleClickScroll("comp-swap","start",100)
                    }
                    const handleClickScroll = async (section,position,aumentar) => {
                        const element = document.getElementById(section);
                        if (element) {
                          // 👇 Will scroll smoothly to the top of the next section
                          //element.scrollIntoView({ behavior: 'smooth' });
                         await element.scrollIntoView({block: position, behavior: 'smooth'});
                        }
                        let tiempo = null
                        //console.log(window.scrollY)
                        tiempo = setTimeout(()=>{
                            window.scroll({
                                top: window.scrollY - aumentar,
                                left: 0,
                                behavior: "smooth",
                              })
                              clearTimeout(tiempo)
                        },1000)
                      }
                    const DesktopVersion = ()=>{
                        if(isMobile) return
                        return (<>
                            <div  className="comp-my-wallet">
                            <div className="div-wallet-star">
                                {!popup &&
                                <button className="favorite fa fa-star" onClick={Favorito} >
                                        <span>{rank_id}</span> 
                                </button>      }
                                {popup && <span>{rank_id}</span> } 
                            </div>
                            <div className="div-wallet-crypto  border-left-2">
                                <div className="cripto-logo-coin">
                                    <img  src={logo} width={30} height={30} />
                                </div>
                                <div className="cripto-names completo tooltip">
                                    <span className="tooltiptext">{name}</span>
                                    <div className="cripto-name"> <b><span className="name-token">{name}</span></b></div>
                                </div>
                                <span>{" /"}</span>
                                <div className="cripto-names">
                                    <div className="cripto-sufijo">{sufijo}</div>
                                </div>
                                {
                                    ath_date?.includes(CurrentYear.toString()) 
                                    && 
                                    <>
                                        <img 
                                            src={"/assets/img/DiamontJustHodl_1.png"} 
                                            width={30} 
                                            height={30} 
                                            data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                            data-tooltip-content={`New ATH in ${CurrentYear}`}
                                            data-tooltip-place="bottom"
                                        />
                                        <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                     </>
                                }
                            </div>
                            {!popup &&
                            <div className="div-wallet-current  border-left-2">
                                <span>${DollarFormat(current_price)}</span> 
                            </div>
                            }
                            <div  className="div-wallet-tokens  border-left-2">
                                <input 
                                    className="input-tokens-wallet"
                                    ref={ref_input}
                                    type={"number"}
                                    defaultValue={cantidad}
                                    disabled={!editable}
                                    placeholder={0.0000}
                                    >
                                </input>
                                <div 
                                    className={"edit-button fa "+(!editable?"fa-pencil":"fa-save")} 
                                    onClick={handlerEdit}>
                                </div>
                            </div>
                            <div className="div-wallet-ath  border-left-2">
                                <span>${DollarFormat(ath_price)}</span>
                            </div>
                            <div className="div-wallet-high  border-left-2">
                                <b> {ToX((((ath_price*100) / current_price )),0)}</b>
                            </div>
                            {!popup &&
                            <div className="div-wallet-date  border-left-2">
                                {ath_date}
                            </div>}
                            {!popup &&
                            <div className="div-wallet-drop  border-left-2">
                                <div className='div-drop p-p'>
                                    <div style={{flexDirection:'column',display:"flex"}}>
                                        <span>{(((current_price * 100) / ath_price )-100).toFixed(2) }%</span>
                                    {/* <img alt='' className="grafica" src={grafica_7d} height={30} />*/}
                                        <object className="grafica-svg" data={grafica_7d}  height="30"> </object>
                                        
                                    </div>
                                </div>
                            </div>}
                            <div className="div-wallet-total  border-left-2">
                                ${DollarFormat(TypePrice=="ATH"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }
                            </div>
                            <div className="div-wallet-del  border-left">
                                {IsLogged && 
                                <button 
                                className="buton-del" 
                                data-index={id}
                                onClick={popup?BorrarPop:Borrar}>
                                <i className="fa fa-trash" data-index={id}></i>
                                </button>
                                }
                            </div>
                        </div>
                        
                        </>)
                    }
                    const MobileVersion = ()=>{
                        if(!isMobile) return
                        return (<>
                            <div  className={"comp-my-wallet "+ (expanded?"expanded":"minimized")} >
                                <div className={"div-wallet-star mobile-section-1 "+ (expanded?"expanded":"")} >
                                    <button className="favorite btn-star" onClick={Favorito} style={{zIndex:1}}>
                                        <p className="fa fa-star">
                                            <span >{rank_id}</span>
                                        </p> 
                                    </button> 
                                    {!expanded && 
                                        <>
                                        <div className="div-wallet-crypto  border-left-2">
                                            <span>Crypto</span>
                                            <div className="div-names-crypto">
                                                <div className="cripto-logo-coin">
                                                    <img  src={logo} width={30} height={30} />
                                                </div>
                                                <div className="cripto-names">
                                                    <div className="cripto-name"> <b><span>{name} / </span></b></div>
                                                </div>
                                                <div className="cripto-names">
                                                    <div className="cripto-sufijo">{sufijo}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ath_date?.includes(CurrentYear.toString()) 
                                            && 
                                            <>
                                                <img 
                                                    src={"/assets/img/DiamontJustHodl_1.png"} 
                                                    width={30} 
                                                    height={30} 
                                                    data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                                    data-tooltip-content={`New ATH in ${CurrentYear}`}
                                                    data-tooltip-place="bottom"
                                                />
                                                <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                             </>
                                        }
                                        <button className={"btn-expand " +(expanded?"expanded":"")} onClick={()=>{setExpanded(!expanded)}} >
                                            <span>{"|"}</span> 
                                        </button> 
                                        </>
                                    }
                                    {expanded && 
                                        <>
                                            <button className={"btn-expand " +(expanded?"expanded":"")} onClick={()=>{setExpanded(!expanded)}} >
                                                <span>{"|"}</span> 
                                            </button>
                                        </>
                                    }

                                    
                                </div>
                                <div className={"mobile-section-2 "+(expanded?"expanded":"")}>
                                    {expanded && <>
                                        <div className="div-wallet-crypto expanded  border-left-2">
                                            <span>Crypto</span>
                                            <div className="div-names-crypto">
                                               
                                                    <div className="cripto-logo-coin">
                                                        <img  src={logo} width={30} height={30} />
                                                    </div>
                                                    <div className="cripto-names">
                                                        <div className="cripto-name"> <b><span>{name} / </span></b></div>
                                                    </div>
                                                    <div className="cripto-names">
                                                        <div className="cripto-sufijo">{sufijo}</div>
                                                    </div>
                                                {
                                                    ath_date?.includes(CurrentYear.toString()) 
                                                    && 
                                                    <>
                                                        <img 
                                                            src={"/assets/img/DiamontJustHodl_1.png"} 
                                                            width={30} 
                                                            height={30} 
                                                            data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                                            data-tooltip-content={`New ATH in ${CurrentYear}`}
                                                            data-tooltip-place="bottom"
                                                            style={{marginLeft:10,marginTop:-10,maxWidth:15}}
                                                        />
                                                        <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                                     </>
                                                   
                                                }
                                                
                                            </div>
                                           
                                        </div>
                                         <div className="div-wallet-current expanded border-left-2">
                                            <span className="title-mobile-current">Current Prices</span>
                                            <span>${DollarFormat(current_price)}</span> 
                                        </div>
                                        </>
                                    }
                                    {!expanded && <>
                                        <div className="div-wallet-crypto expanded  border-left-2" >
                                            <span>Tokens</span>
                                            <div className="div-names-crypto" >
                                                <input 
                                                    className="input-tokens-wallet"
                                                    ref={ref_input}
                                                    type={"number"}
                                                    defaultValue={cantidad}
                                                    disabled={!editable}
                                                    placeholder={0.0000}
                                                    style={{maxHeight:"25px",width:"100%",borderRadius:"5px"}}
                                                    >
                                                </input>
                                                <div 
                                                    className={"edit-button fa "+(!editable?"fa-pencil":"fa-save")} 
                                                    onClick={handlerEdit}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-wallet-current expanded border-left-2">
                                            <span className="title-mobile-current">{TypePrice=="ATH"?"Value in ATH":"Current Value"}</span>
                                            <span>${DollarFormat(TypePrice=="ATH"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }</span>
                                        </div>
                                    </>}
                                </div>

                                {expanded && <>
                                    <div className={"mobile-section-2 "+(expanded?"expanded":"")}> 
                                        <div className="div-wallet-crypto expanded  border-left-2">
                                            <span>Tokens</span>
                                            <div className="div-names-crypto" >
                                                <input 
                                                    className="input-tokens-wallet"
                                                    ref={ref_input}
                                                    type={"number"}
                                                    defaultValue={cantidad}
                                                    disabled={!editable}
                                                    placeholder={0.0000}
                                                    style={{maxHeight:"25px",width:"100%",borderRadius:"5px"}}
                                                    >
                                                </input>
                                                <div 
                                                    className={"edit-button fa "+(!editable?"fa-pencil":"fa-save")} 
                                                    onClick={handlerEdit}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-wallet-current expanded border-left-2">
                                            <span className="title-mobile-current">ATH</span>
                                            <span>${DollarFormat(ath_price)}</span>
                                        </div>
                                    </div>

                                    <div className={"mobile-section-2 "+(expanded?"expanded":"")}> 
                                        <div className="div-wallet-crypto expanded  border-left-2">
                                            <span>To High</span>
                                            <div className="div-names-crypto " style={{fontSize:20,textAlign:"right",width:"100%"}}>
                                                <b style={{fontSize:25,textAlign:"left",width:"90%"}}> 
                                                    {ToX((((ath_price*100) / current_price )),0)}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="div-wallet-current expanded border-left-2">
                                            <span className="title-mobile-current">Date ATH</span>
                                            <span>{ath_date}</span> 
                                        </div>
                                    </div>


                                    <div className={"mobile-section-2 mobile-5 "+(expanded?"expanded":"")}> 
                                        <div className="div-wallet-crypto expanded " style={{border:"none",width:"100%"}}>
                                            <span>Price Drop ATH</span>
                                            <div className="div-names-crypto" style={{width:"100%"}}>
                                                <div className="cripto-logo-coin" 
                                                    style={{
                                                        borderLeft:"none",
                                                        width:"100%",
                                                        display:"flex",
                                                        justifyContent:"space-between"
                                                        }}>
                                                    <object 
                                                        className="grafica-svg" 
                                                        data={grafica_7d}  
                                                        height="35"
                                                    > 
                                                    </object>
                                                    <span style={{fontSize:20,fontWeight:600}}>
                                                        {(((current_price * 100) / ath_price )-100).toFixed(2) }%
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mobile-section-2 mobile-5 "+(expanded?"expanded":"")} style={{border:"none"}}> 
                                        <div className="div-wallet-crypto expanded  border-left-2"  style={{border:"none"}}>
                                            <span>{TypePrice=="ATH"?"Value in ATH":"Current Value"}</span>
                                            <div className="div-names-crypto" style={{fontSize:25,textAlign:"left",width:"90%"}}>
                                                <b style={{fontSize:25,textAlign:"left",width:"90%"}}>
                                                    ${DollarFormat(TypePrice=="ATH"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }
                                                </b>
                                                <div className="cripto-names">
                                                    {IsLogged && 
                                                        <button 
                                                        className="buton-del" 
                                                        data-index={id}
                                                        onClick={popup?BorrarPop:Borrar}
                                                        style={{fontSize:25,background:"transparent"}}
                                                        >
                                                        <i className="fa fa-trash" data-index={id}></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </>}
                            </div>
                        </>)
                    }
                    return (<>
                        <MobileVersion />
                        <DesktopVersion />
                    </>
                    )
                }}
                
            </Contexto.Consumer>)}
    </Global.Consumer>)
}



/* 
return (
<Contexto.Consumer>
    {(ctx)=>{
        
    }}
</Contexto.Consumer>) 
*/