import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import {Header, SiteLogo,Footer} from '../../components';
import '../../App.css';
import './register.css'
import './responsive_register.css'
import { faEyeSlash,faEye,faLock,faEnvelope,faUser} from "@fortawesome/free-solid-svg-icons";
import { useLocation, Navigate } from 'react-router-dom';
import {Helpers} from '../../helpers'
import PopUp from '../../components/popups/GeneralPopup';
import { useGAEvent } from '../../App';




function Register({Contexto}) {
    let location = useLocation();
    const [mode, setMode] = useState('register');
    const [Popup,setPopup] = useState({status:false,message:""})
    const [user, setUser] = useState([]);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [token,setToken] = useState(null)
    const [tokenLocal,setTokenLocal] = useState()
    const [viewPass, setViewPass] = useState('password')
    const [iconEye, setIconEye] = useState(true)
    const [resend,setResend] = useState(false)
    const [time,setTime] = useState(0)
    const input_user = useRef()
    const input_email = useRef()
    const input_pass = useRef()
    const input_token = useRef()


    useEffect( () => {
        //verificar si hay algun login
        window.scrollTo(0, 0);
        verifySession();
    }, [mode]);
    const verifySession = async() =>{
        const currentSession = await localStorage.getItem('userSession','')
        
        if(currentSession != '' &&  currentSession != undefined &&  currentSession != null){
            ShowPop("You already have an active session: " + currentSession,"close","info","")
            window.location.href = '/home';
        }
    }
    const Login = async ()=>{
        if(username == '' || pass == ''){ShowPop('User or pass is empty.',"close","error","Something is wrong");return}
        let url = `/test/?function=GetUser&username=${username}&pass=${pass}` 
        await api
        .get(url)
        .then((response) =>{ 
            let userDB = response.data.content;
            if(userDB.length > 0){
                if(userDB[0].password == pass){
                    setUser(userDB[0]);
                    setPass('')
                    //console.log(location)
                    //console.log(user)
                    localStorage.setItem('userSession',userDB[0].username)
                    window.location.href = '/home';
                } else {
                    //console.log(userDB[0].password)
                }
            } else {
                ShowPop('Invalid user or pass.',"close","error","Something is wrong")
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }
    const Register = async()=>{
        //ShowPop('try register')
        let url = `/test/?function=verifyUser&username=${username}&email=${email}` 
        //console.log("username")
        //console.log(username)
        if(username == '' ){
            ShowPop('User  is empty.',"close","error","Something is wrong")
            return;
        }
        if(pass == ''){
            ShowPop('Pass is empty.',"close","error","Something is wrong")
            return;
        }
        if(email == ''){
            ShowPop('Email is empty.',"close","error","Something is wrong")
            return;
        }



        await api
            .get(url)
            .then((response) =>{ 
                //console.log(response)
                let userDB = response.data.content;
                if(userDB.length  > 0){
                    ShowPop('User already exist.',"close","error","Something is wrong")
                    useGAEvent("Register","Intento registrar con un usuario existente")
                    changeMode(1)
                    return;
                } else if (email.includes('@') && email.includes('.') &&  userDB.length <= 0 ){

                    let wallets =  localStorage.getItem('wallets-temp')
                    if(wallets == null || wallets == undefined || wallets == "" ){
                        wallets = "[]" 
                    }
                    let IsWalletCreated = false
                    if(wallets != "[]"){
                        IsWalletCreated = true
                    }
                    let url2 = `/test/?function=CreateUser&username=${username}&pass=${pass}&email=${email}&wallets=${wallets}` 
                    api
                        .get(url2)
                        .then((response) =>{ 
                            
                            
                            ShowPop('The Account from '+username+' has been created.',"confirm","pass","Congratulations")
                            localStorage.setItem('userSession',username)
                            localStorage.setItem('wallets-temp','[]')
                            if(IsWalletCreated){

                                useGAEvent("Register","Se creo una cuenta sin wallet exitosamente",`usuario: ${username}`)
                            } else {

                                useGAEvent("Register","Se creo una cuenta con wallet exitosamente",`usuario: ${username}`)
                            }
                            setUsername('')
                            setPass('')
                            changeMode(0)
                        })
                        .catch((err) => {
                            changeMode(1)
                            console.error("ops! ocorreu um erro" + err);
                        });
                } else {
                    changeMode(1)
                    ShowPop('Invalid Email.',"close","error","Something is wrong")
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                return;
            });
    }
    const ShowPop = (_text,_type="close",_icon="info",_title="",handlers = {}) =>{        
        setPopup({            
            status:true,
            message:_text,
            type:_type,
            icon:_icon,
            title:_title,
            Confirm:handlers?.Confirm,
            Accept:handlers?.Accept
        })        
    }
    const handlerUser=()=>{
        setUsername(input_user.current.value)
    }
    const handlerPass=()=>{
        setPass(input_pass.current.value)
    }
    const handlerEye =()=>{
        setIconEye(!iconEye);
        setViewPass(iconEye?'text':'password');
    }
    const handlerEmail=()=>{
        setEmail(input_email.current.value)
    }
    const handlerToken=()=>{
        //email verification
        setToken(input_token.current.value)
    }
    const startTime = () =>{
     
        //setTime(seconds)
       // console.log('time ->' +time)
        if(time > 0){
            let _time = time - 1
            //console.log('time 2 ->' +_time)
            setTime(_time)
        }else{
            setResend(false)
            //console.log('segundo')
        }

        
    }
    const sendTokenToEmail = async(_token) =>{
       if(_token > 0){
        //console.log('email enviado')
        await fetch(`https://justhodl.io/api/email/verification/?name=${username}&email=${email}&code=${_token}` )
       }else {
        //console.log('email no enviado')
       }
       
    }
    const AccountNotExist = async () =>{
        //console.log("verificando cuenta")
        let url = `/test/?function=verifyUser&username=${username}&email=${username}` 
        //console.log(url)
        let retorno = true;
        await api.get(url)
            .then((response) =>{ 
                let userDB = response.data.content;
                if(userDB.length > 0){
                    retorno = true;
                } else {
                    //console.log("no existe cuenta")
                    retorno = false;
                }
            })
        return retorno;
    }
    const EmailNotExist = async () =>{
        //console.log("verificando email")
        let url = `/test/?function=verifyUser&username=${email}&email=${email}` 
        //console.log(url)
        let retorno = true;
        await api.get(url)
            .then((response) =>{ 
                let userDB = response.data.content;
                if(userDB.length  > 0){
                    retorno = true;
                } else {
                    //console.log("no existe email")
                    retorno = false;
                }
            })
        return retorno;
    }
    const handlerSend = async() => {
        //send token from email
        //antispan
        
        if(username == '' ){
            ShowPop('User is empty.',"close","error","Something is wrong")
            return;
        }
        if(email == ''){
            ShowPop('Email is empty.',"close","error","Something is wrong")
            return;
        }
        if(pass == '' ){
            ShowPop('Pass is empty.',"close","error","Something is wrong")
            return;
        }
        const verifyAccount = await AccountNotExist();
        const verifyEmail = await EmailNotExist();

        if(verifyAccount){
            ShowPop(
                'An account already exists with this user, Do you want to log in?.',
                "cancel confirm",
                "alert",
                "We're sorry",
                {Confirm:()=>{
                    changeMode(0);
                    setPopup({status:false,message:"",type:"",icon:"",title:""})
                }}
            )
            //changeMode(0)
            return;
        }
        if(verifyEmail){
            ShowPop('The Email already exists.',"close","error","Something is wrong")
            return;
        }
        if(time==0){
            setTime(60)
            const _token = Math.floor((Math.random() * 1000000) + 100000);
            changeMode(2) 
            setTokenLocal(_token)
            setResend(true)
            //ShowPop(_token)
            //aqui se envia el token al email
            sendTokenToEmail(_token)
        }
        
    }
    const ValidateToken = async() => {
        //send token from email
        //antispan
        if(token==tokenLocal){
            //se puede enviar el mail
            
            Register()
            SaveTokensOnRegister(username)
            
        } else {
            ShowPop('Invalid verification code try again.',"close","error","Something is wrong")
        }
        
    }
    const changeMode = (_mode) =>{
        switch (_mode) {
            case 0:setMode('login'); break;
            case 1:setMode('register'); break;
            case 2:setMode('token'); break;
            default:setMode('login'); break;
        }
    }
    const handlerSession = () =>{
        switch (mode) {
            case 'token':ValidateToken(); break;
            case 'login':Login();  break;
            case 'register':handlerSend(); break;
            default:Login(); break;
        }
    }
    useEffect(()=>{
        //{console.log('inicio useeffec')}
        let contador = setTimeout(()=>{
            startTime()
        },1000)
        if(time<1){
            setTime(0)
            setResend(false)
            clearTimeout(contador)
        }
        //return clearTimeout(contador)
    },[time])
    const StoresLogin = () =>{
        return (<> <span className="form-or hide">OR</span>
        <button className="btn-google hide">Continue with Google</button>
        <button className="btn-apple hide">Continue with Apple</button></>
        )
    }

    

    return (<Contexto.Consumer>
        {(contexto)=>{
            const {isLoading,globalSession,globalTokens,isLogged,C} = contexto
            
            return (<>
               
               {Popup?.status && <PopUp data={Popup} defaultAction={setPopup}/>}
                
                <div className="page-session">
                    {mode =='login' && <Header Contexto={Contexto} login/>}
                    {mode !='login' && <Header Contexto={Contexto} register/>}
                    <div className="form-session">
                        <div className="form-session-left">
                            {mode=='token'?<h4>Email Verification!</h4>:<h3>Hello!</h3>}
                            {mode=='token'
                            &&
                            <p className='session-token-text'>We have sent a verification 
                                code to your email, please enter
                                it to verify and create your account. <br />
                                <b>(Please, check your junk inbox)</b>
                            </p> }
                            <div className="form-session-inputs">
                                {mode!='token' 
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faUser,'session-icon-left')}
                                    <input 
                                        ref={input_user}
                                        className='session-user-input'
                                        type={'text'}
                                        onChange={handlerUser}
                                        placeholder={mode=='login'?'Username or Email':'Username'}
                                        value={username}
                                    />
                                    {new Helpers().Icon(faEye,'session-icon-right hidden')}
                                </div>}
                                {mode!='token'
                                &&
                                mode!='login'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faEnvelope,'session-icon-left')}
                                    <input 
                                        ref={input_email}
                                        className='session-email-input'
                                        type={'email'}
                                        onChange={handlerEmail}
                                        placeholder={'Email'}
                                        value={email}
                                    />
                                    {new Helpers().Icon(faEye,'session-icon-right hidden')}
                                </div>}
                                {mode!='token'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_pass}
                                        className='session-pass-input'
                                        type={viewPass}
                                        onChange={handlerPass}
                                        placeholder={'Password'}
                                        value={pass}
                                    />
                                    <div
                                        onClick={handlerEye}  
                                    >
                                        {new Helpers().Icon(faEye,iconEye?'session-icon-right ':'session-icon-right hidden')}
                                        {new Helpers().Icon(faEyeSlash,iconEye?'session-icon-right hidden':'session-icon-right')}
                                    </div>
                                </div>}
                                {mode=='token'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_token}
                                        className='session-token-input'
                                        type={'number'}
                                        onChange={handlerToken}
                                        placeholder={'Validation code'}
                                        value={token}
                                        maxLength={5}
                                    />
                                    <span 
                                        className='session-send-right'
                                        onClick={handlerSend}
                                    >
                                        {resend?'resend('+time+'s)':'send code'}
                                    </span>
                                </div>}
                            </div>
                            {mode=='login'
                            &&
                            <div className="session-form-remember">
                                <div className="check-remember">
                                    <input type="checkbox" ></input>
                                    <span>Remember me</span>
                                </div>
                                <span>Forgot password?</span>
                            </div>}
                            <button 
                                className="btn-session-login"
                                onClick={handlerSession}
                            >
                                {mode=='login' && 'Log in'}
                                {mode=='register' && 'Register'}
                                {mode=='token' && 'Validate email'}
                                
                            </button>
                           
                        </div>
                        <div className="form-session-right">
                            <img src={C.SITE_LOGO_WHITE_V}  height={150}/>
                        </div>
                    </div>
                    
                </div>
                <Footer />  
            </>);
    }}
    </Contexto.Consumer>)
  }
  
  export default Register;

  /* <div className={'testeo'}> 
            <p>username:{username}</p>
            <p>pass:{pass}</p>
            <p>email:{email}</p>
            <p>token:{token}</p>
        </div>
        <div className={'testeo'}> 
            <button onClick={()=>{changeMode(0)}}>login</button>
            <button onClick={()=>{changeMode(1)}}>register</button>
            <button onClick={()=>{changeMode(2)}}>token</button>
            <h3>Mode:{mode}</h3>
        </div> */