import React from "react";

function DollarFormat (value){
    //console.log('valor: '+value)
    let completo  = parseFloat(value).toFixed(10)
    
    let entero  = parseInt(value).toString();
    let cant = entero.length;
    let contador = 0;
    let cadena = ''

    //agregarle la , en los miles
    for (let char = cant; char != 0;char--) {
        contador++;
        
        if(contador == 4){
            cadena += ','
        } else if(contador == 7){
            cadena += ','
        } else if(contador == 10){
            cadena += ','
        } else if(contador == 13){
            cadena += ','
        } else if(contador == 16){
            cadena += ','
        }
        cadena += entero[char-1];
    }


    let salida = ''

    for (let char2 = (cadena.length); char2 != 0;char2--) {
       
        salida += cadena[char2-1];
       
    } 
    let stringALL = completo.toString();
    //aqui tenemos toda la cadena y vamos a ver lo de los decimales
    //125.220002
    //0.0000000554
    
    if(value > 0.01 && stringALL.length > 3){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
    } else if(value > 0.001 && stringALL.length > 4){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
    } else if(value > 0.0001 && stringALL.length > 5){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
    } else if(value > 0.00001 && stringALL.length > 6 ){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
        salida +=stringALL.at(-6)
    } else if(value > 0.00001 && stringALL.length > 7 ){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
    
    } else if(value > 0.000001 && stringALL.length > 8 ){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
        salida +=stringALL.at(-4)
    
    } else if(value > 0.0000001 && stringALL.length > 9 ){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
        salida +=stringALL.at(-4)
        salida +=stringALL.at(-3)
    
    } else if(value > 0.00000001 && stringALL.length > 10 ){
        salida +=stringALL.at(-11)
        salida +=stringALL.at(-10)
        salida +=stringALL.at(-9)
        salida +=stringALL.at(-8)
        salida +=stringALL.at(-7)
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
        salida +=stringALL.at(-4)
        salida +=stringALL.at(-3)
        salida +=stringALL.at(-2)

    } else {
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
        salida +=stringALL.at(-4)
    }
     
    /*
    if(value<0.01){
        salida =  stringALL
    } else {
        salida +=stringALL.at(-6)
        salida +=stringALL.at(-5)
        salida +=stringALL.at(-4)
    }*/
    return salida;
        
    //return completo;
}
export default DollarFormat;