import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Card, SiteLogo,FormularioLogin,Footer} from '../../components';
import '../../App.css';
import './privacity.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEyeSlash,faEye,faLock,faEnvelope,faBell} from "@fortawesome/free-solid-svg-icons";
import { data } from "jquery";
import {Link } from 'react-router-dom';
import { useLocation, Navigate } from 'react-router-dom';
import { useGAEvent } from '../../App';
function Privacity({Contexto}) {
    const [Popup,setPopup] = useState(false)
    return (<>
        <Header Contexto={Contexto} home menu/>
        <div className="form-legal-notice" >
                <br />
                <div style={{color:"black",display:"flex",flexDirection:"column",alignItems:"center",width:"100%",background:""}}>
                    <h3>Privacy Policy</h3>
                    <p>
                        At justhodl.io, we’re emphatically committed to securing and regarding your security.
                    </p>
                    <p>
                        This Security Approach (“Privacy Policy”) covers your get to and utilize of this site, JustHodl.io (the “Website”), as well as related substance, program, and versatile applications (collectively, the “Service”).
                    </p>
                    <p>
                        This Security Approach clarifies when, why and how we collect and utilize individual data around individuals who visit our Site or utilize our Administrations, the conditions beneath which we may unveil your individual data to others, and how we keep your individual data secure.

                    </p>
                    <p>
                        We may alter this Protection Arrangement from time to time. By utilizing our Site or our Administrations, you're concurring to be bound by this Protection Approach.
                    </p>
                    <p><b>What Data Do We Collect?</b></p>
                    <p>                    
                        JustHodl (“JustHodl”, “we” or “us”) collects (a) the mail addresses of those who communicate with us by means of e-mail; (b) total data concerning what pages clients get to or visit; (c) data given by the client (such as overview data and/or location enrollments); and (d) data related to your utilize of the Site and/or the portable application, counting IP address, Gadget ID, geographic area, and date and time of your ask; (e) any other information that you just might give voluntarily. 
                        We might moreover collect your mail address.
                    </p>
                    <p>
                        We don't intentionally collect information from children under the age of 18. By utilizing the JustHodl benefit, you're speaking to simply are at slightest 18 a long time ancient.

                    </p>
                    <p><b>How Do We Utilize the Data?</b></p>
                    <p>JustHodl.io employments collected data for the taking after purposes:</p>
                    <p>
                        To fulfill a contract or take steps connected to a contract such as handling your enlistment on our Site or sending you data about changes to our terms or policies;
                    </p>


                    <p>
                        Where it is essential for purposes which are in JustHodl’s true blue interface such as (a) to supply the data or substance you've got asked; (b) to contact you approximately our programs, items, highlights or administrations; (c) to improve our administrations and for inside trade purposes such as distinguishing proof and verification or client benefit, portfolio following and client preference syncing between gadgets; (d) to guarantee the security of our Site, by attempting to anticipate unauthorized or pernicious exercises; (e) to implement compliance with our Terms of Utilize and other policies; (f) to assist other organizations (such as copyright proprietors) to enforce their rights; and (g) to tailor substance, notices, and offers for you or for other purposes uncovered at the time of collection.
                    </p>


                    <p>
                        In the event that you are doing not wish to get promoting data around our programs, items, highlights or administrations, you'll be able opt-out of showcasing communications.

                    </p>
                    <p>
                        Where we are lawfully required to do so. We may moreover give get to to your actually identifiable data when legitimately required to do so, to participate with police examinations or other legitimate procedures, to secure against abuse or unauthorized utilize of our Site, to restrain our legitimate obligation and secure our rights, or to secure the rights, property or security of guests of the Site or the open.In those occurrences, the data is given as it were for that reason.
                    </p>
                    <p><b>How Do We Share Your Data?</b></p>

                    <p>
                        We don't offer your individual information to other organizations for commercial purposes. We as it were share your personal data to supply items or administrations you’ve asked, when we have your authorization, or beneath the taking after circumstances:

                    </p>
                    <p>
                        When it is essential to share data in arrange to examine, avoid, or take activity with respect to unlawful exercises, suspected extortion, circumstances including potential dangers to the physical security of any individual.
                    </p>
                    <p>
                        We offer individual data to trusted businesses or persons for the sole reason of handling by and by identifying information on our sake and give us with administrations. When this can be done, it is subject to understandings that oblige those parties to prepare such data only on our enlightening and in compliance with this Protection Arrangement and fitting privacy and security measures.
                    </p>

                    <p>
                        We offer such data to third parties who have entered into non-disclosure assentions with us.</p>
                    <p>
                        We offer such data to a company controlled by, or beneath common control with, JustHold for any reason allowed by this Security Arrangement.</p>
                    <p>
                        We may total, anonymize, and distribute information for factual and inquire about purposes only. For illustration, we may compile and share data related to the notoriety of certain items followed by clients. In any such occasion, the data will not be able to be followed back to any person.
                    </p>
                    <p><b>How Do We Share Your Data?</b></p>
                    <p>
                        We take safety measures to guarantee the security of your individual data. We guarantee that our Site is secured by sensible security measures managed by current technology, which all our information has and servers are additionally protected by such security measures, counting but not restricted to firewalls. We emphatically advise you to alter your secret word habitually and not to utilize the same secret word for distinctive websites. We cannot secure, nor does this Security Arrangement apply to, any data that you just transmit to other clients. You ought to never transmit individual or distinguishing data to other clients.
                    </p>
                    <p><b>Retention of Your Individual Data</b></p>
                    <p>
                        We hold data as long because it is essential to supply the Administrations asked by you and others, subject to any lawful obligations to further hold such data. Data related together with your account will by and large be kept until it is not vital to supply the Administrations or until you inquire us to erase it or your account is erased, whichever comes to begin with. Furthermore, we may hold data from erased accounts to comply with the law, anticipate extortion, resolve debate, troubleshoot issues, help with examinations, uphold the Terms of Utilize, and take other activities allowed by law. The data we hold will be taken care of in agreement with this Privacy Arrangement.
                    </p>
                    <p><b>EU and EEA Users’ Rights</b></p>
                    <p>
                        In the event that you're a inhabitant of the European Financial Region, you for the most part have the proper to get to, correct, download or eradicate your data, as well as the proper to limit and question to certain preparing of your data. Whereas a few of these rights apply generally, certain rights apply as it were in certain restricted circumstances.
                    </p>
                    <p>
                        You have got the correct to get to your individual information and, on the off chance that essential, have it corrected, erased or confined. In certain occurrences, you'll have the correct to the transportability of your information. If you don't mind contact daniel@justhodl.io to fulfill any demands relating to your information subject rights.
                    </p>
                    <p>
                        You'll be able moreover inquire us not to send showcasing communications and not to utilize your individual information when we carry out profiling for coordinate marketing purposes. You'll select out of getting e-mail newsletters and other showcasing communications by taking after the opt-out instructions given to you in those e-mails. Transactional account messages will be unaffected indeed in case you select out from promoting communications.
                    </p>
                    <p><b>Complaints</b></p>
                    <p>
                        Ought to you want to raise a concern approximately our utilize of your data (and without preference to any other rights you'll have), it would be ideal if you reach out to daniel@justhodl.io. On the off chance that our answer isn't palatable, you've got the proper to raise a concern along with your nearby supervisory specialist, depending on where you're found.
                    </p>
                    <p><b>Questions</b></p>
                    <p>
                        Any questions approximately this Protection Arrangement or almost our utilize of your individual data ought to be tended to to daniel@justhodl.io
                    </p>                   
                </div>

        </div>
        <div className="privacy-back-to-home" >
            <Link key={'link012'}   style={{color:"green",marginTop:20}} onClick={()=>window.location.replace('/')}>
                <b >GO BACK TO HOME</b>
            </Link>
        </div>
        
        <Footer PopUp={setPopup} />   
        <div style={{marginBottom:50}}></div>
        </>);
  }
  
  export default Privacity;