import React, { useState,useRef, useEffect } from "react";
import  "./myWalletTest.css";
import  "./myWalletTest_responsive.css";
import { isMobile } from 'react-device-detect';
import Constants from '../../helpers/constants/Constants'
import api from "../../services/api";
import {Error,Icon,Intervalo,Consola,Loading} from './Componentes/Helpers'
import {Contexto} from '../../pages/test/Test'
import {Wallet} from './Componentes/Wallet'
import {WalletPop} from './Componentes/WalletPop'
import {AddWallet} from './Componentes/AddWallet'
import {SaveChanges,SaveChangesPop} from './Componentes/SaveChanges'
import {Total,TotalPop} from './Componentes/Total'
import {ButtonsToMyWallet} from './Componentes/ButtonsWallet'
import Title from "../title";

import {HeadAthMyWallet,HeadAthMyWalletPop} from './Componentes/HeaderWallet'
const C = new Constants();

///componente principal
const MyWalletTest = ({ShowPop}) => {
    //definimos las variables de estado internas
    const [IsInternalLoading,setIsInternalLoading] = useState(true)
    const [Pop,setPop] = useState(false)

    //controlador de ejecucion y cambio de estado de los componentes
    useEffect(()=>{
        //desactivamos la carga
        setIsInternalLoading(false)
    },[])
    useEffect(()=>{
       
        //StaticScroll(Pop)
      
    },[Pop])


    
    return (
    <Contexto.Consumer>
        {(ctx)=>{
            const {TokenList,TokenListToAdd,GlobalTokens,handlerAddWallet,TypePrice} = ctx
            const ShowWallets = () =>{
                //console.log()
                const OrderList = TokenList.slice().sort((a,b)=> {
                    
                    if(TypePrice=="ATH"){
                        return (b.cantidad * b.ath_price) - (a.cantidad * a.ath_price)
                    } 
                    return (b.cantidad * b.current_price) - (a.cantidad * a.current_price)
                })
                return OrderList.map((t,i)=><Wallet key={`w${i}`} tkn={t}/>)
            } 
                

            const ShowWalletsPop = () => TokenListToAdd.sort((a,b)=> {
                if(TypePrice=="ATH"){
                    return (b.cantidad * b.ath_price) - (a.cantidad * a.ath_price)
                } 
                return (b.cantidad * b.current_price) - (a.cantidad * a.current_price)
            }).map((t,i)=><WalletPop key={`wp${i}`} tkn={t}/>)
            
            return IsInternalLoading ?
            <Loading /> 
            :(
            <div id="comp-wallet" className="comp-wallet" style={isMobile?{marginTop:50}:{}}>
                <div className="myWallet">
                    <Title  
                        title={C.SECTION_MYWALLET_TITLE} 
                        icon={C.SECTION_MYWALLET_ICON} 
                        subtitle={C.SECTION_MYWALLET_SUBTITLE}
                        info={"Simulate the ATH value of your cryptocurrency portfolio, by creating an ATH wallet. Build your ATH wallet by inputting the data of your positions. You have complete flexibility to update or modify your ATH wallet."}
                        label={"my-wallet"}
                    />
                    <ButtonsToMyWallet contexto={ctx} setPop={setPop}/>
                    <HeadAthMyWallet />
                    <div className="my-wallet-scroll WalletPop">
                        <ShowWallets />
                    </div>
                    <Total contexto={ctx}/> 
                    <SaveChanges contexto={ctx} ShowPop={ShowPop} />
                    
                </div>
                
                <br /><br />
                {Pop && 
                <div className="amw-pop" >
                    <div className="amw-pop-content">
                        <div className="amw-pop-content-row-1">
                            <Title  
                                title={"MY WALLET"}  
                                icon={'wallet-icon-green'}   
                                subtitle={"Add new tokens to your wallet"} 
                                info={"Create your portfolio with all your cryptocurrencies and discover the estimated value at All Time High (ATH). Once your portfolio is registered, you can modify/update it whenever you make any transactions to have thorough control of your portfolio updated to All Time High (ATH)."}
                                label={"my-wallet"}
                            />
                            <button 
                                onClick={()=>setPop(false)}
                                className="btn-close-pop"
                                >
                                    CLOSE
                            </button>
                        </div>    
                        <div className="amw-pop-content-row-2">
                            <AddWallet  contexto={ctx}/>
                        </div>              
                        <div className="amw-pop-content-row-3">
                            <HeadAthMyWalletPop />
                            <div className="my-wallet-scroll WalletPop">
                                <ShowWalletsPop />
                            </div>
                        </div>   
                        <div className="amw-pop-content-row-4">
                            <SaveChangesPop contexto={ctx} setPop={setPop} ShowPop={ShowPop}/>
                            <TotalPop contexto={ctx}/> 
                        </div> 
                    </div>
                </div>
                }
            </div>
            )
        }}
    </Contexto.Consumer>)
}










export default MyWalletTest

