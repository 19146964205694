import React, { useState,useRef, useEffect } from "react";
import styled  from "styled-components";
import {Link } from 'react-router-dom';
import {Contexto} from '../../../pages/test/Test'
const LinkA = styled(Link)`
    color:green;
    background:white;
    border:solid 2px green;
    font-size: 18px;
    padding: 10px 40px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    &:hover{
        color:white;
        background:green;
        border:solid 2px green;
    }
`

//aqui iniicia el nuevo componente
export const SaveChanges =  ({contexto,ShowPop}) =>{
    const {SaveSession,IsLogged} = contexto
    const handlerSave = ()=>{
        SaveSession("home")
    }
    
    if(!IsLogged){
        return (<div id="comp-my-wallet-finish" className="comp-save">
            <LinkA  to="/Register" >SAVE YOUR WALLET</LinkA>
            </div>)
    }
    return (<div id="comp-my-wallet-finish" className="comp-save">
        <LinkA onClick={handlerSave}>SAVE YOUR CHANGES</LinkA>
        </div>)
}




export const SaveChangesPop =  ({contexto,setPop,ShowPop}) =>{
    const {SaveSession,IsLogged,TokenListToAdd,StaticScroll} = contexto
    const HandlerTokenTemp = ()=>{
        //enviar a local storage
        localStorage.setItem('wallets-temp',JSON.stringify(TokenListToAdd))
    }
    const handlerSave = ()=>{
        SaveSession("pop")
        setPop(false)
        StaticScroll(false)
    }

    if(!IsLogged){
        return (<div id="comp-my-wallet-finish" className="comp-save">
            <LinkA  onClick={HandlerTokenTemp} to="/Register" >SAVE YOUR WALLET</LinkA>
            </div>)
    }
    return (<div id="comp-my-wallet-finish" className="comp-save">
        <LinkA onClick={handlerSave}>SAVE YOUR CHANGES</LinkA>
        </div>)
}