
//CONSTANTES
class Constants {
  constructor(){
    this.DEFAULT_SESSION = {
      id: "0",
      username:"local",
      email:"",
      ip:"",
      date:"",
      wallet_token: "",
      wallet_values: "",
      wallets:[
        {
          id:"1",
          name:"Bitcoin",
          sufijo:"BTC",
          cantidad:0.5,
          ath_date:"",
          ath_price:"",
          current_price:"",
          drop_porcent:"-"  ,    
          grafica_7d:"",
          high_porcent:"",
          logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
          rank_id:"",
          volume_24h:""
        },
        {
          id:"1027",
          name:"Ethereum",
          sufijo:"ETH",
          cantidad:5,
          ath_date:"",
          ath_price:"",
          current_price:"",
          drop_porcent:"-"  ,    
          grafica_7d:"",
          high_porcent:"",
          logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
          rank_id:"",
          volume_24h:""
        },
        {
          id:"1839",
          name:"BNB",
          sufijo:"BNB",
          cantidad:10,
          ath_date:"",
          ath_price:"",
          current_price:"",
          drop_porcent:"-"  ,    
          grafica_7d:"",
          high_porcent:"",
          logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
          rank_id:"",
          volume_24h:""
        },
        {
          id:"52",
          name:"XRP",
          sufijo:"XRP",
          cantidad:10000,
          ath_date:"",
          ath_price:"",
          current_price:"",
          drop_porcent:"-"  ,    
          grafica_7d:"",
          high_porcent:"",
          logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
          rank_id:"",
          volume_24h:""
        }
      ]
    }
    this. DEFAULT_TOKENS = 
    [
      {
        id:"1",
        name:"Bitcoin",
        sufijo:"BTC",
        cantidad:0.5,
        ath_date:"",
        ath_price:"",
        current_price:"",
        drop_porcent:"-"  ,    
        grafica_7d:"",
        high_porcent:"",
        logo:"",
        rank_id:"",
        volume_24h:""
      },
      {
        id:"1027",
        name:"Ethereum",
        sufijo:"ETH",
        cantidad:5,
        ath_date:"",
        ath_price:"",
        current_price:"",
        drop_porcent:"-"  ,    
        grafica_7d:"",
        high_porcent:"",
        logo:"",
        rank_id:"",
        volume_24h:""
      },
      {
        id:"1839",
        name:"BNB",
        sufijo:"BNB",
        cantidad:10,
        ath_date:"",
        ath_price:"",
        current_price:"",
        drop_porcent:"-"  ,    
        grafica_7d:"",
        high_porcent:"",
        logo:"",
        rank_id:"",
        volume_24h:""
      },
      {
        id:"52",
        name:"XRP",
        sufijo:"XRP",
        cantidad:10000,
        ath_date:"",
        ath_price:"",
        current_price:"",
        drop_porcent:"-"  ,    
        grafica_7d:"",
        high_porcent:"",
        logo:"",
        rank_id:"",
        volume_24h:""
      }
    ]
    this.DEFAULT_COIN_SUFIJO = 'BTC'
    this.DEFAULT_COIN_NAME = 'Bitcoin (BTC)'
    this.DEFAULT_COIN_ICON = './assets/img/coin/64x64/1.png'
    this.DEFAULT_COIN_PRICE = 30041.80
    this.DEFAULT_COIN_ATH= 69045.00
    this.DEFAULT_COIN_LABEL = 'Select a token'
    this.DEFAULT_PRICE_TYPE = 'ath'
    this.DEFAULT_LS_WALLETS = 'wallets'
    this.ZERO = 0
    this.ONE = 1
    this.NULL = null
    this.EMPTY = ''
    this.UNDEFINED = undefined
    this.SECTION_CALCULATOR_TITLE = ""
    this.SECTION_MYWALLET_TITLE = 'My Wallet ATH'
    this.SECTION_SWAP_TITLE = 'Swap ATH'
    this.SECTION_RANKING_TITLE = ""
    this.SECTION_CALCULATOR_SUBTITLE = ""
    this.SECTION_MYWALLET_SUBTITLE = 'Create and save your own wallet to see what the ATH of your cryptocurrency investments is.'
    this.SECTION_SWAP_SUBTITLE = 'Swap cryptocurrencies to find out how much they are worth at their ATH.'
    this.SECTION_RANKING_SUBTITLE = "Discover the change in a cryptocurrency’s ranking since its’ ATH."
    this.SECTION_MYWALLET_ICON = 'wallet-icon-green'
    this.SECTION_SWAP_ICON = 'swap-icon-new'
    this.SECTION_SWAP_ICON_WHITE = 'swap-icon-new-white'
    this.SITE_LOGO_COLOR = '../../../assets/principal/logo_jh_color.png'
    this.SITE_LOGO_WHITE = '../../../assets/principal/logo_jh_white.png'
    this.SITE_LOGO_COLOR_V = '../../../assets/principal/logo_jh_color_vertical.png'
    this.SITE_LOGO_WHITE_V = '../../../assets/principal/logo_jh_white_vertical.png'
    this.SITE_NAME = 'swap-icon-green'
    
  }
  GetUserURL = (funtion,parametro=null,datos={}) =>{
    switch(funtion){
      case "verifyUser":return `/test/?function=verifyUser&username=${parametro}&email=${parametro}`;
    }
  }
}





/*
ACA, ONE, PLA, HOT, RUNE, GMT, LIT, GAL,

*/






export default Constants