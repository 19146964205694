import React, { useEffect, useState,useRef } from "react";
import  "./swapAth.css";
import { isMobile } from 'react-device-detect';
import {Title} from '..';
import Select  from 'react-select';
import {DollarFormat,TokenFormat, ToX} from '../../helpers'
import { useGAEvent } from '../../App';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
const SwapSelector = ({contexto}) =>{
    const {globalTokens,coinSpawIcon, handlerFavorito,coinSpawName,setCoinSpawValue,coinSpawValue} = contexto
    const [options,setOptions] = useState(coinSpawValue)
    const select_swap = useRef();
    const Opciones = async() =>{
        const options_temp = [];
        await globalTokens.map((e)=>{
            options_temp.push({
                label :  e.sufijo, 
                value : `${e.id}`,
                icon : `${e.logo}`,
                id : `${e.id}`,
                price_ath : e.ath_price,
                current_value : (e.current_price),
                sufijo : e.sufijo,
                ath_date : e.ath_date,
                name  :e.name,
                drop_porcent : e.drop_porcent,
                grafica_7d : e.grafica_7d,
                high_porcent : e.high_porcent,
                rank_id : e.rank_id
            })
        })
        setOptions(options_temp)
    }
    useEffect(()=>{
        Opciones();
    },[globalTokens,coinSpawValue])

    const handlerValue = () =>{
        setCoinSpawValue(select_swap.current.value)
    }

    const HandlerSelectorAdd = (token) =>{
        const {sufijo,icon,label} = token
        handlerFavorito(label,sufijo,select_swap.current.value,icon)
        useGAEvent("SwapAth","Se selecciono otro token en el swap-ath directamente")
    }

    return(<>
    <div className="comp-selector-swap">
        <input 
            ref={select_swap}
            className="swap-input"
            type="number"
            value={coinSpawValue}
            onChange={handlerValue}
            placeholder={TokenFormat(0.0000)}
            
        /> 
        <div className="search-name-swap" >   
            <img className="img-swap" src={coinSpawIcon} height={20} width={20} />
            <Select 
                className="select-swap"
                isSearchable
                onChange={HandlerSelectorAdd}
                options={options} 
                placeholder={coinSpawName}
            />
        </div>  
    </div>
    </>)
}
const HeadAthSpawn = () =>{
    return (<>
        <div className="comp-swap-title">
            <div className="div-swap-index">
                <span>#</span>
            </div>
            <div className="div-swap-crypto border-left">
                <span>CRYPTO</span>
            </div>
            <div className="div-swap-current border-left">
                <span>CURRENT PRICE</span>
            </div>
            <div className="div-swap-ath border-left">
                <span>ATH</span>
            </div>
            <div className="div-swap-high border-left">
                <span>TO HIGH</span>
            </div>
            <div className="div-swap-date border-left">
                <span>DATE ATH</span>
            </div>
            <div className="div-swap-drop border-left">
                <span>PRICE DROP ATH</span>
            </div>
            <div className="div-swap-total border-left">
                <span>VALUE SWAP ATH</span>
            </div>
        </div>
    </>);
}

const SwapRows = ({contexto}) =>{
    const {globalTokens,coinSpawValue,coinSpawName,TypePrice} = contexto
    const [value,setValue] = useState(0)
    const [expanded,setExpanded] = useState(false)
    const ConverToDollar = (_coinName,_value) =>{
        let _result = 0;
        globalTokens.map((tkn)=>{
            const {sufijo,current_price} = tkn
            if(sufijo == _coinName){
                _result =  _value * current_price;
            }
        }) 
        return _result.toFixed(8);
    } 
    const inDollar = ConverToDollar(coinSpawName,coinSpawValue);
    const order100 = globalTokens.sort((a,b)=>a.rank_id - b.rank_id).slice()
    const max100 = order100.filter((a,i)=>i < 100)
    const CurrentYear = new Date().getFullYear()
    const tooltipstyle = { 
        backgroundColor: "green", 
        color: "#fff",
        width:"150px",
        minWidth:"150px",
        maxWidth:"50vw",
        zIndex:10000,
        fontSize:"14px",
        borderRadius:"10px",
        opacity:1,
        textTransform:"none",
        fontWeigth:600
    }
    return (
    <div className="comp-swap-section">
        {!isMobile && <HeadAthSpawn />}
        <div className="comp-swap-scroll-rows">
            {   max100.map((token,index) => {
                const {
                    id,
                    logo,
                    sufijo,
                    name,
                    current_price,
                    ath_price,
                    high_porcent,
                    ath_date,
                    drop_porcent,
                    grafica_7d
                } = token
                let inATHDollar = (inDollar / current_price) * ath_price;
                const MobileVersion = ()=>{
                    if(!isMobile) return
                    return (<>
                        <div  className={"comp-my-wallet "+ (expanded?"expanded":"minimized")} >
                            <div className={"div-wallet-star mobile-section-1 "+ (expanded?"expanded":"")} >
                                <button className="favorite btn-star"  >
                                    <p className="fa fa-star">
                                    <span>{index +1}</span>
                                    </p> 
                                </button> 
                                {!expanded && 
                                    <>
                                    <div className="div-wallet-crypto  border-left-2">
                                        <span>Crypto 
                                        </span>
                                        <div className="div-names-crypto">
                                            <div className="cripto-logo-coin">
                                                <img  src={logo} width={30} height={30} />
                                            </div>
                                            <div className="cripto-names">
                                                <div className="cripto-name"> <b><span>{name} / </span></b></div>
                                            </div>
                                            <div className="cripto-names">
                                                <div className="cripto-sufijo">{sufijo}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                       ath_date?.includes(CurrentYear.toString()) 
                                       && 
                                       <>
                                           <img 
                                               src={"/assets/img/DiamontJustHodl_1.png"} 
                                               width={30} 
                                               height={30} 
                                               data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                               data-tooltip-content={`New ATH in ${CurrentYear}`}
                                               data-tooltip-place="bottom"
                                           />
                                           <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                        </>
                                    }
                                    <button className={"btn-expand " +(expanded?"expanded":"")} onClick={()=>{setExpanded(!expanded)}} >
                                        <span>{"|"}</span> 
                                    </button> 
                                    </>
                                }
                                {expanded && 
                                    <>
                                        <button className={"btn-expand " +(expanded?"expanded":"")} onClick={()=>{setExpanded(!expanded)}} >
                                            <span>{"|"}</span> 
                                        </button>
                                    </>
                                }

                                
                            </div>
                            <div className={"mobile-section-2 "+(expanded?"expanded":"")}>
                                {expanded && <>
                                    <div className="div-wallet-crypto expanded  border-left-2">
                                        <span>Crypto</span>
                                        <div className="div-names-crypto">
                                            <div className="cripto-logo-coin">
                                                <img  src={logo} width={30} height={30} />
                                            </div>
                                            <div className="cripto-names">
                                                <div className="cripto-name"> <b><span>{name} / </span></b></div>
                                            </div>
                                            <div className="cripto-names">
                                                <div className="cripto-sufijo">{sufijo}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        ath_date?.includes(CurrentYear.toString()) 
                                        && 
                                        <>
                                            <div>
                                                <span style={{color:"green",fontWeight:800}}>ATH</span>
                                                <img 
                                                    src={"/assets/img/DiamontJustHodl_1.png"} 
                                                    width={30} 
                                                    height={30} 
                                                    data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                                    data-tooltip-content={`New ATH in ${CurrentYear}`}
                                                    data-tooltip-place="bottom"
                                                />
                                                <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                            </div>
                                         </>
                                       
                                    }
                                    </>
                                }
                                {!expanded && <>
                                    <div className="div-wallet-crypto expanded  border-left-2" >
                                       
                                        <span>To High</span>
                                        <div className="div-names-crypto " style={{fontSize:20,textAlign:"right",width:"100%"}}>
                                            <b style={{fontSize:25,textAlign:"right",width:"90%"}}> 
                                                {ToX((((ath_price*100) / current_price )),0)}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div-wallet-current expanded border-left-2">
                                        <span className="title-mobile-current">Value Swap ATH</span>
                                        <span>${DollarFormat(inATHDollar) }</span>
                                    </div>
                                </>}
                            </div>

                            {expanded && <>
                                <div className={"mobile-section-2 "+(expanded?"expanded":"")}> 
                                    <div className="div-wallet-crypto expanded  border-left-2">
                                        <span className="title-mobile-current">Current Prices</span>
                                        <span><b>${DollarFormat(current_price)}</b></span> 
                                    </div>
                                    <div className="div-wallet-current expanded border-left-2">
                                        <span className="title-mobile-current">ATH</span>
                                        <span>${DollarFormat(ath_price)}</span>
                                    </div>
                                </div>

                                <div className={"mobile-section-2 "+(expanded?"expanded":"")}> 
                                    <div className="div-wallet-crypto expanded  border-left-2">
                                        <span>To High</span>
                                        <div className="div-names-crypto " style={{fontSize:20,textAlign:"right",width:"100%"}}>
                                            <b style={{fontSize:25,textAlign:"left",width:"90%"}}> 
                                                {ToX((((ath_price*100) / current_price )),0)}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div-wallet-current expanded border-left-2">
                                        <span className="title-mobile-current">Date ATH</span>
                                        <span>{ath_date}</span> 
                                    </div>
                                </div>


                                <div className={"mobile-section-2 mobile-5 "+(expanded?"expanded":"")}> 
                                    <div className="div-wallet-crypto expanded " style={{border:"none",width:"100%"}}>
                                        <span>Price Drop ATH</span>
                                        <div className="div-names-crypto" style={{width:"100%"}}>
                                            <div className="cripto-logo-coin" 
                                                style={{
                                                    borderLeft:"none",
                                                    width:"100%",
                                                    display:"flex",
                                                    justifyContent:"space-between"
                                                    }}>
                                                <object 
                                                    className="grafica-svg" 
                                                    data={grafica_7d}  
                                                    height="35"
                                                > 
                                                </object>
                                                <span style={{fontSize:20,fontWeight:600}}>
                                                    {(((current_price * 100) / ath_price )-100).toFixed(2) }%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mobile-section-2 mobile-5 "+(expanded?"expanded":"")} style={{border:"none"}}> 
                                    <div className="div-wallet-crypto expanded  border-left-2"  style={{border:"none"}}>
                                        <span>{"Value Swap ATH"}</span>
                                        <div className="div-names-crypto" style={{fontSize:25,textAlign:"left",width:"90%"}}>
                                            <b style={{fontSize:25,textAlign:"left",width:"90%"}}>
                                                ${DollarFormat(inATHDollar) }
                                            </b>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </>}
                        </div>
                    </>)
                }
                const DesktopVersion = ()=>{
                    if(isMobile)return
                    return (<div key={"spaw-"+index}>
                        <div className="comp-swap-row">
                            <div className="div-index-swap">
                                <span>{index +1}</span>
                            </div>
                            <div className="div-crypto-swap border-swap-left-2">
                                <img src={logo} width={30} height={30} />
                                <div className="cripto-sufijo-swap">{sufijo}</div>
                                <div className="cripto-name-swap">{name}</div>
                                {
                                   ath_date?.includes(CurrentYear.toString()) 
                                   && 
                                   <>
                                       <img 
                                           src={"/assets/img/DiamontJustHodl_1.png"} 
                                           width={30} 
                                           height={30} 
                                           data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                           data-tooltip-content={`New ATH in ${CurrentYear}`}
                                           data-tooltip-place="bottom"
                                       />
                                       <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                    </>
                                }
                            </div>
                            <div className="div-current-swap border-swap-left-2">
                                <span>${DollarFormat(current_price)}</span>
                            </div>
                            <div className="div-ath-swap border-swap-left-2">
                            <span> ${DollarFormat(ath_price)}</span>
                            </div>
                            <div className="div-high-swap border-swap-left-2">
                                <b>{ToX(high_porcent,1)}</b>
                            </div>
                            <div className="div-date-swap border-swap-left-2">
                                <span>{ath_date}</span>
                            </div>
                            <div className="div-drop-swap border-swap-left-2">
                                <div style={{flexDirection:'column',display:"flex"}}>
                                    <span>{parseFloat(drop_porcent).toFixed(2)}%</span>
                                    <img alt='' className="grafica" src={grafica_7d} height={30} />
                                </div>
                            </div>
                            <div className="div-total-swap border-swap-left-2">
                                <span>${DollarFormat(inATHDollar)}</span>
                            </div>
                        </div>
                    </div>);
                }
                return (<div key={"swap-ath-index-"+index}>
                    <MobileVersion />
                    <DesktopVersion />
                </div>)
                
            })}
        </div>
    </div>)
}
const SwapAth = ({Contexto}) =>{

    const dropP = () =>{
        let entero = Math.abs(parseInt(dropPorcent));
        
        if(entero >= 90){
            entero = 90;
        } else if(entero >= 80){
            entero = 80;
        } else if(entero >= 70){
            entero = 70;
        } else if(entero >= 60){
            entero = 60;
        } else if(entero >= 50){
            entero = 50;
        } else if(entero >= 40){
            entero = 40;
        } else if(entero >= 30){
            entero = 30;
        } else if(entero >= 20){
            entero = 20;
        } else if(entero >= 10){
            entero = 10;
        } else {
            entero = 0;
        }
        let classes = "div-dropPorcent div-center p"+entero;
        return (<div className={'div-dropPorce div-center'} style={{'flexDirection':'column'}}>
                {dropPorcent}%
                <img className="grafica" src={grafica} height={30} width={60} /> 
                </div>
            )
    }
    return (<Contexto.Consumer>
        {(contexto)=>{ 
             const {coinSpawValue,C} = contexto
            return (
                <div id="comp-swap" className="comp-swap-ath">
                    <Title 
                        title={C.SECTION_SWAP_TITLE} 
                        icon={C.SECTION_SWAP_ICON}
                        subtitle={C.SECTION_SWAP_SUBTITLE}
                        info={"Identify opportunities by switching between cryptocurrencies to compare how much they were worth at their ATH: you can analyse whether it is optimal to hold or exchange a position with one cryptocurrency, with little growth margin (X), for another cryptocurrency that may have a greater projection, meaning a greater margin from its current value to its ATH. This function serves to assist you in finding and determining profitable alternatives to your plans with any specific cryptocurrency."}
                        label={"swap"}
                    />
                    <SwapSelector contexto={contexto} />
                    <SwapRows contexto={contexto} />
                </div>
            )
        }}
    </Contexto.Consumer>)
}
  
export default SwapAth;