import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import {Header, SiteLogo,Footer} from '../../components';
import '../../App.css';
import './login.css'
import './responsive_login.css'
import { faEyeSlash,faEye,faLock,faEnvelope,faUser} from "@fortawesome/free-solid-svg-icons";
import { useLocation, Navigate } from 'react-router-dom';
import {Helpers} from '../../helpers'
import PopUp from '../../components/popups/GeneralPopup';
import { useGAEvent } from '../../App';

function Login({Contexto}) {
    let location = useLocation();
    const [mode, setMode] = useState('login');
    const [Popup,setPopup] = useState({status:false,message:""})
    
    const [user, setUser] = useState([]);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [reNewPass, setReNewPass] = useState('');
    const [token,setToken] = useState(0)
    const [tokenLocal,setTokenLocal] = useState()
    const [viewPass, setViewPass] = useState('password')
    const [iconEye, setIconEye] = useState(true)
    const [resend,setResend] = useState(false)
    const [time,setTime] = useState(0)
    const input_user = useRef()
    const input_email = useRef()
    const input_pass = useRef()
    const input_new_pass = useRef()
    const input_re_new_pass = useRef()
    const input_token = useRef()


    useEffect( () => {
        //verificar si hay algun login
        verifySession();
    }, []);
    const verifySession = async() =>{
        const currentSession = localStorage.getItem('userSession')
        if(currentSession != '' &&  currentSession != undefined &&  currentSession != null){
            ShowPop("You already have an active session: " + currentSession,"close","info","")
            window.location.href = '/home';
        }
    }

    const Login = async ()=>{
        if(username == '' || pass == ''){ShowPop('User or pass is empty.',"close","error","Something is wrong");return}
        let url = `/test/?function=GetUser&username=${username}&pass=${pass}` 
        await api
        .get(url)
        .then((response) =>{ 
            let userDB = response.data.content;
            if(userDB.length > 0){
                if(userDB[0].password == pass){
                    setUser(userDB[0]);
                    setPass('')
                    //console.log(location)
                    //console.log(user)
                    localStorage.setItem('userSession',userDB[0].username)
                    window.location.href = '/home';
                } else {
                    //console.log(userDB[0].password)
                }
            } else {
                ShowPop('Invalid user or pass.',"close","error","Something is wrong")
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }
    const Register = async()=>{
        //ShowPop('try register')
        let url = `/test/?function=verifyUser&username=${username}&email=${email}` 
        if(username == '' || pass == '' || email == ''){
            ShowPop('User or pass is empty.',"close","error","Something is wrong")
            return;
        }
        await api
            .get(url)
            .then((response) =>{ 
                //console.log(response)
                let userDB = response.data.content;
                if(userDB > 0){
                    ShowPop('User already exist.',"close","error","Something is wrong")
                    changeMode(1)
                    return;
                } else if (email.includes('@') && email.includes('.') &&  userDB.length <= 0 ){
                    let url2 = `/test/?function=CreateUser&username=${username}&pass=${pass}&email=${email}` 
                    api
                        .get(url2)
                        .then((response) =>{ 
                            ShowPop('User created.',"confirm","pass","Congratulations")
                            setUsername('')
                            setPass('')
                            changeMode(0)
                        })
                        .catch((err) => {
                            changeMode(1)
                            console.error("ops! ocorreu um erro" + err);
                        });
                } else {
                    changeMode(1)
                    ShowPop('Invalid Email.',"close","error","Something is wrong")
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                return;
            });
    }
    const handlerUser=()=>{
        setUsername(input_user.current.value)
    }
    const handlerPass=()=>{
        setPass(input_pass.current.value)
    }
    const handlerNewPass=()=>{
        setNewPass(input_new_pass.current.value)
    }
    const handlerReNewPass=()=>{
        setReNewPass(input_re_new_pass.current.value)
    }
    const handlerEye =()=>{
        setIconEye(!iconEye);
        setViewPass(iconEye?'text':'password');
    }
    const handlerEmail=()=>{
        setEmail(input_email.current.value)
    }
    const handlerToken=()=>{
        //email verification
        setToken(input_token.current.value)
    }
    const startTime = () =>{
     
        //setTime(seconds)
       // console.log('time ->' +time)
        if(time > 0){
            let _time = time - 1
            //console.log('time 2 ->' +_time)
            setTime(_time)
        }else{
            setResend(false)
            //console.log('segundo')
        }

        
    }
    const sendTokenToEmail = async(_token) =>{
       if(_token > 0){
        //console.log('email enviado') actualizado
        await fetch(`https://justhodl.io/api/email/tokenReset/?name=${email}&email=${email}&code=${_token}`)
       }else {
        console.log('email no enviado')
       }
       
    }
    const VerifyIfEmailExist = async () =>{
        let url = `/test/?function=verifyUser&username=${email}&email=${email}` 
        let respuesta = false;
        await api.get(url)
            .then((response) =>{ 
                //console.log(response.data)
                let userDB = response.data.content;
                if(userDB.length > 0){
                    respuesta = true;
                } 
            })
        return respuesta;
    }
    const handlerSend = async() => {
        //antispan
        //check if email exist
        const Exist = await VerifyIfEmailExist();
        if(Exist){
            //console.log("el email existe")
            if(time==0){
                setTime(60)
                const _token = Math.floor((Math.random() * 1000000) + 100000);
                changeMode(2)
                setTokenLocal(_token)
                setResend(true)
                sendTokenToEmail(_token)
                //console.log(_token)
            }
        } else {
            ShowPop('The email '+email+' not exist in justhodl database',"close","error","Something is wrong")
        }
        
    }
    const handlerSetPass = async() => {
        if(newPass == "" || newPass.includes(" ")){
            ShowPop('The fields must not be empty or with spaces',"close","error","Something is wrong")
            return;
        }
        if(reNewPass == "" || reNewPass.includes(" ")){
            ShowPop('The fields must not be empty or with spaces',"close","error","Something is wrong")
            return;
        }
        if(newPass ===  reNewPass){
            //enviar a sistema
            sendNewPass()
        } else {
            ShowPop('The Passwords do not match',"close","error","Something is wrong")
        }
        
    }
    const CleanPasswords = () =>{
        setReNewPass('')
        setNewPass('')
        setPass('')
    }
    const sendNewPass = async() => {
        let url = `/test/?function=newPass&email=${email}&pass=${reNewPass}`
        await api.get(url)
        url = `/test/?function=getUserByEmail&email=${email}`
        await api
        .get(url)
        .then((response) =>{ 
            let userDB = response.data.content;
            if(userDB.length > 0){
                if(userDB[0].password == reNewPass){
                    ShowPop('Password reset successfully.',"confirm","pass","Congratulations")
                    CleanPasswords()
                    //console.log(response)
                    changeMode(0)
                } else {
                    ShowPop('Password reset error.',"close","error","Something is wrong")
                }
            } else {
                ShowPop('Account not exist.',"close","error","Something is wrong")
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }
    const ValidateToken = async() => {
        //send token from email
        if(token==tokenLocal){
            //console.log("modo:"+mode + " ahora setMode(3)")
            changeMode(3)
            //console.log("modo:"+mode)
        } else {
            ShowPop('Invalid verification code try again.',"close","error","Something is wrong")
        }
        
    }
    const changeMode = (_mode) =>{
        switch (_mode) {
            case 0:setMode('login'); break;
            case 1:setMode('register'); break;
            case 2:setMode('token'); break;
            case 3:setMode('newpass'); break;
            case 4:setMode('resetpass'); break;
            default:setMode('login'); break;
        }
    }
    const handlerSession = () =>{
        switch (mode) {
            case 'token':ValidateToken(); break;
            case 'login':Login();  break;
            case 'register':handlerSend(); break;
            case 'resetpass':handlerSend(); break;
            case 'newpass':handlerSetPass(); break;
            default:Login(); break;
        }
    }
    useEffect(()=>{
        //{console.log('inicio useeffec: modo:' + mode)}
        let contador = setTimeout(()=>{
            startTime()
        },1000)
        if(time<1){
            setTime(0)
            setResend(false)
            clearTimeout(contador)
        }
        //return clearTimeout(contador)
    },[time])

    const StoresLogin = () =>{
        return (<> <span className="form-or hide">OR</span>
        <button className="btn-google hide">Continue with Google</button>
        <button className="btn-apple hide">Continue with Apple</button></>
        )
    }
    const ShowPop = (_text,_type="close",_icon="info",_title="",handlers = {}) =>{        
        setPopup({            
            status:true,
            message:_text,
            type:_type,
            icon:_icon,
            title:_title,
            Confirm:handlers?.Confirm,
            Accept:handlers?.Accept
        })        
    }
    return (<Contexto.Consumer>
        {(contexto)=>{
            const {isLoading,globalSession,globalTokens,isLogged,C} = contexto
            
            return (<>
                {/*console.log('renderizando')*/}
                {Popup?.status && <PopUp data={Popup} defaultAction={setPopup}/>}
                <div className="page-session">
                    {mode =='login' && <Header Contexto={Contexto}  login/>}
                    {mode !='login' && <Header Contexto={Contexto}  register/>}
                    <div className="form-session">
                        <div className="form-session-left">
                            {mode=='token' && <h3>Email Verification!</h3>}
                            {mode=='register' && <h3>Recover password!</h3>}
                            {mode=='newpass' && <h3>Password Reset!</h3>}  
                            {mode=='login' && <h3>Hello!</h3>}    
                            {mode=='newpass' &&

                            <span className='session-token-text' style={{color:"gray",marginBottom:10}}>{email}</span> }
                                                      
                            {mode=='token' &&

                            <p className='session-token-text'>We have sent a verification 
                                code to your email, please enter
                                it to verify and restore your password.
                            </p> }
                            {mode=='register'
                            &&
                            <p className='session-token-text'>
                                Enter your registered email to reset your password.
                            </p> }
                            <div className="form-session-inputs">
                                {mode!='token' 
                                && mode!="register"
                                
                                &&
                                mode!='newpass' 
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faUser,'session-icon-left')}
                                    <input 
                                        ref={input_user}
                                        className='session-user-input'
                                        type={'text'}
                                        onChange={handlerUser}
                                        placeholder={mode=='login'?'Username or Email':'Username'}
                                        value={username}
                                    />
                                    {new Helpers().Icon(faEye,'session-icon-right hidden')}
                                </div>}
                                {mode!='token'
                                &&
                                mode!='login'
                                &&
                                mode!='newpass' 
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faEnvelope,'session-icon-left')}
                                    <input 
                                        ref={input_email}
                                        className='session-email-input'
                                        type={'email'}
                                        onChange={handlerEmail}
                                        placeholder={'Email'}
                                        value={email}
                                    />
                                    {new Helpers().Icon(faEye,'session-icon-right hidden')}
                                </div>}
                                
                                {mode!='token' && mode!="register"
                                && mode!='newpass' &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_pass}
                                        className='session-pass-input'
                                        type={viewPass}
                                        onChange={handlerPass}
                                        placeholder={'Password'}
                                        value={pass}
                                    />
                                    <div
                                        onClick={handlerEye}  
                                    >
                                        {new Helpers().Icon(faEye,iconEye?'session-icon-right ':'session-icon-right hidden')}
                                        {new Helpers().Icon(faEyeSlash,iconEye?'session-icon-right hidden':'session-icon-right')}
                                    </div>
                                </div>}
                                {mode=='newpass' &&
                                    <span className='session-token-text'>Insert your new password</span> 
                                }
                                {mode=='newpass'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_new_pass}
                                        className='session-pass-input'
                                        type={viewPass}
                                        onChange={handlerNewPass}
                                        placeholder={'Enter your Password'}
                                        value={newPass}
                                    />
                                    <div
                                        onClick={handlerEye}  
                                    >
                                        {new Helpers().Icon(faEye,iconEye?'session-icon-right ':'session-icon-right hidden')}
                                        {new Helpers().Icon(faEyeSlash,iconEye?'session-icon-right hidden':'session-icon-right')}
                                    </div>
                                </div>}
                                {mode=='newpass' &&
                                    <span className='session-token-text'>Repeat your new password</span> 
                                }
                                {mode=='newpass'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_re_new_pass}
                                        className='session-pass-input'
                                        type={viewPass}
                                        onChange={handlerReNewPass}
                                        placeholder={'Re-enter your Password'}
                                        value={reNewPass}
                                    />
                                    <div
                                        onClick={handlerEye}  
                                    >
                                        {new Helpers().Icon(faEye,iconEye?'session-icon-right ':'session-icon-right hidden')}
                                        {new Helpers().Icon(faEyeSlash,iconEye?'session-icon-right hidden':'session-icon-right')}
                                    </div>
                                </div>}





                                {mode=='token'
                                &&
                                <div className="session-input">
                                    {new Helpers().Icon(faLock,'session-icon-left')}
                                    <input 
                                        ref={input_token}
                                        className='session-token-input'
                                        type={'number'}
                                        onChange={handlerToken}
                                        placeholder={'Validation code'}
                                        value={token}
                                        maxLength={5}
                                    />
                                    <span 
                                        className='session-send-right'
                                        onClick={handlerSend}
                                    >
                                        {resend?'resend('+time+'s)':'send code'}
                                    </span>
                                </div>}
                            </div>


                            {mode=='login'
                            &&
                            <div className="session-form-remember">
                                <div className="check-remember">
                                    <input type="checkbox" ></input>
                                    <span>Remember me</span>
                                </div>
                                <button 
                                    className=""
                                    style={{background:"transparent",border:"none",cursor:"pointer"}}
                                    onClick={()=>setMode("register")}
                                >
                                    <span>Forgot password?</span>
                                </button>
                            </div>}
                            <button 
                                className="btn-session-login"
                                onClick={handlerSession}
                            >
                                {mode=='login' && 'Log in'}
                                {mode=='register' && 'Send Email'}
                                {mode=='token' && 'Validate email'}
                                {mode=="newpass" && 'Set Password'}
                                
                            </button>
                        </div>
                        <div className="form-session-right">
                            <img src={C.SITE_LOGO_WHITE_V}  height={150}/>
                        </div>
                    </div>
                    
                </div>
                <Footer />  
            </>);
        }}
    </Contexto.Consumer>)

    
  }
    
  
  export default Login;
