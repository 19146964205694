import React, { useState,useRef, useEffect } from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import {Contexto} from '../../../pages/test/Test'

//aqui iniicia el nuevo componente
export const Total = ({contexto}) =>{
    
    return <Contexto.Consumer>
        {(ctx)=> {
            const {GlobalTotal,TypePrice} = ctx
            //console.log(TypePrice)
            if(TypePrice =='CURRENT'){
                return (<div  className="comp-total">
                    <span>Total value (Current)</span> 
                    <b>${DollarFormat(GlobalTotal)}</b>
                </div>)
            } 
            return(<div  className="comp-total">
                <span>Total value (ATH) </span>
                <b>${DollarFormat(GlobalTotal)}</b>
            </div>)
        }}
    </Contexto.Consumer>
    
}

//aqui iniicia el nuevo componente
export const TotalPop = ({contexto}) =>{
    return <Contexto.Consumer>
        {(ctx)=> {
            const {GlobalTotalPop,TypePrice} = ctx
            //console.log(TypePrice)
            if(TypePrice =='CURRENT'){
                return (<div  className="comp-total">
                    <span>Total value (Current)</span> 
                    <b>${DollarFormat(GlobalTotalPop)}</b>
                </div>)
            } 
            return(<div  className="comp-total">
                <span>Total value (ATH) </span>
                <b>${DollarFormat(GlobalTotalPop)}</b>
            </div>)
        }}
    </Contexto.Consumer>
    
}