import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Card, SiteLogo,FormularioLogin,Footer} from '../../components';
import '../../App.css';
import './cookies.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEyeSlash,faEye,faLock,faEnvelope,faBell} from "@fortawesome/free-solid-svg-icons";
import { data } from "jquery";
import {Link } from 'react-router-dom';
import { useLocation, Navigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { useGAEvent } from '../../App';

function Cookies({Contexto}) {
    const [Popup,setPopup] = useState(false)
        
    return (<>
        <Header Contexto={Contexto} home menu />
        <div className="form-cookies" >
                <br />
                <div style={{color:"black",display:"flex",flexDirection:"column",alignItems:"center",background:""}}>
                    <h3>Just Hodl Cookie Policy</h3>
                    <p>
                        This cookie policy describes how JustHodl uses “cookies” and other similar technologies, in connection with our websites and applications.
                        JustHodl uses cookies to improve your experience and for marketing. Please review your cookie settings in order to manage your privacy.
                    </p>
                    <p><b>What are cookies?</b></p>
                    <p>
                        JustHodl are small text files that are stored in a computer's browser directory. They help site providers with things like language preferences, understanding how people use a site, remembering login details and storing site preferences.
                        There are two main types of cookies that can be set:
                    </p>
                    <div>
                        <ul style={{textAlign:"left"}}>
                            <li>ensure that our services function properly</li>
                            <li>detect and prevent fraud</li>
                            <li> understand how visitors use and engage with our websites and applications</li>
                            <li>analyse and improve our services</li>
                        </ul>
                    </div>
                    <p><b>Does JustHodl use cookies for marketing, analytics and personalisation?</b></p>
                    <p><b>Marketing</b></p>
                    <p>
                        Yes. We and our service providers will use cookies and similar technologies on our websites and applications to direct JustHodl ads to you through targeted advertisements for JustHodl services on other sites you visit and to measure your engagement with those ads.
                    </p>
                    <p><b>Analytics</b></p>
                    <p>
                        Analytics cookies help us understand how visitors interact with our services. We use those cookies in a number of different ways, including:
                    </p>
                    <p style={{margin:isMobile? "0px 20px":"0px 200px"}} >
                        <span>
                            <b>1.</b>  To prevent you needing to reconfigure your settings each time you log in, we use cookies to remember how you prefer to use JustHodl services;
                        </span><br />
                        <span>
                            <b>2.</b> To understand how people reach the JustHodl site and give us insights into improvements we need to make to our website;
                        </span><br />
                        <span>
                            <b>3.</b> Pixel tags may be used in connection with certain services to track the actions of our email recipients and to track the success of JustHodl marketing campaigns.
                        </span><br />
                        <span>
                            <b>4.</b> We use Google Analytics (third party analytics) to collect and analyze information about the use of our website and applications and to report on activities and trends. This service may also collect information regarding the use of other sites, apps and online resources.
                        </span>

                    </p>
                    <p><b>For Personalisation</b></p>
                    <p>
                        Binance uses preference cookies to remember your preferences and to recognize you when you return to use our services.
                    </p>
                    <p><b>Can I opt out?</b></p>
                    <p>
                        Depending on where you are based, you can adjust your preferences about cookies through our cookie setting center. If you want to remove existing cookies from your device, you can do this using your browser options. If you want to block future cookies being placed on your device, you can do so by modifying the settings at our cookie setting center. Nevertheless, please consider that deleting and blocking cookies may have an impact on your user experience.
                    </p>
                    <p><b>Which cookies do JustHodl use?</b></p>
                    <p>
                        Please see the table below. However, it is important to note that not all cookies may be used in all jurisdictions or websites.
                    </p>
                    <div>
                        <table  className="cookies-table">
                            <thead >
                                <tr >
                                    <td><b>Category</b></td>
                                    <td ><b>Description</b></td>
                                </tr>                               
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Strictly Necessary cookies</b></td>
                                    <td>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not work. These cookies do not store any personally identifiable information.</td>
                                </tr>
                                <tr>
                                    <td><b>Performance cookies</b></td>
                                    <td>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site and will not be able to monitor its performance.</td>
                                </tr>
                                <tr>
                                    <td><b>Functionality cookies</b></td>
                                    <td>These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by a third-party provider whose services we have added to our pages. If you don’t allow these cookies some services may not function properly.</td>
                                </tr>
                                <tr>
                                    <td><b>Targeting cookies</b></td>
                                    <td>Targeting cookies may be set through our site by our advertising partners. They can be used by these third parties to build a profile of your interests based on the browsing information they collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not allow these cookies you will still see basic advertising on your browser that is generic and not based on your interests</td>
                                </tr>
                                <tr>
                                    <td><b>Social Media Cookies</b></td>
                                    <td>These cookies are set by a range of social media services that we have added to the site to enable you to share our content with your friends and networks. They are capable of tracking your browser across other sites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit. If you do not allow these cookies you may not be able to use or see these sharing tools.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        If you have any questions, please submit your request to daniel@justhodl.io.
                        You can adjust your cookie settings at any time from our cookie setting center.

                    </p>
                </div>

        </div>
        <div className="cookies-back-to-home">
            <Link key={'link012'}   style={{color:"green",marginTop:20}}  onClick={()=>window.location.replace('/')}>
                <b >GO BACK TO HOME</b>
            </Link>
        </div>
        <Footer PopUp={setPopup} />   
        <div style={{marginBottom:50}}></div>
        </>);
  }
  
  export default Cookies;