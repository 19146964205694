import React, { useEffect, useState } from "react";
import { lazy } from 'react';
import api from "../../services/api";
import {Header,Footer,SwapAth,CalculatorAth,Ranking,MyWallet} from '../../components';

import PopUp from '../../components/popups/GeneralPopup';
import {PopUpCookies} from '../../components/popups/GeneralPopup';
import { useGAEvent } from '../../App';

lazy(() => import('../../App.css'));
lazy(() => import('./home.css'));
lazy(() => import('./home_responsive.css'));


//import '../../App.css';
//import './home.css';
//import './home_responsive.css';
import MyWalletNew from '../test/Test'
import { bindKey } from "lodash";
import { isTablet } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCheck, faClose, faInfo, faPassport, faWarning } from "@fortawesome/free-solid-svg-icons";

const Loader = () =>{
    return (<>
    <div className="loading">
        Loading
    </div>
    </>)
}
const DefaultPop = {
    status:false,
    message:"message",
    type:"close confirm",
    icon:"close",
    title:"title"
}





const Home = ({Contexto}) =>{
    const [lastUpdate,setLastUpdate]  = useState(0)
    const [tokens,setTokens]  = useState([{}])
    const [tokensX,setTokensX]  = useState([{}])
    const [sinceTotalList,setSinceTotalList]  = useState(12)
    const [orderSince,setOrderSince]  = useState('capital')
    const [Popup,setPopup] = useState(DefaultPop)
    const [EndOfPage,setEndOfPage] = useState(false)

    const GetLastUpdate = async( )=>{
        await api.get('https://justhodl.io/api/v1/test/?function=last')
        .then((res)=>setLastUpdate(res.data.content[0].Tiempo )) 
        .catch((err)=>console.log(err))
    }

    const GetTokens = async () => {
        await api.get("/test/?function=GetAllToken")
        .then((res2) => setTokens(res2.data.content))
        .catch((err) => console.error("ops!" + err))
        await api.get("/test/?function=GetTokensOrderX")
        .then((res2) => {
            
            setTokensX(res2.data.content)
        })
        .catch((err) => console.error("ops!" + err))
    }
    const Intervalo = (_function,_time) => {
        setInterval(_function, _time);
    }
    function userReachedBottom() {
        if(EndOfPage) return false
        const scrollPosition = window.scrollY + window.innerHeight ;
        const documentHeight = document.documentElement.offsetHeight - 1000;
        return scrollPosition >= documentHeight;
      }

    useEffect(()=>{
        
        GetLastUpdate();
        GetTokens();
        Intervalo(GetTokens,900000);
        Intervalo(GetLastUpdate,10000);

    },[])
    
    useEffect(()=>{
        
        const evento = window.addEventListener('scroll', () => {
            const fin = userReachedBottom()
            if (fin ) {                
                setEndOfPage(true)
                
            }
          });
          return () => window.removeEventListener("scroll", evento)
    },
    [])

    useEffect(()=>{
        //
       if(EndOfPage) useGAEvent("HOME END","Un usuario llego al fin de la pagina")
    },
    [EndOfPage])


    const ShowPop = (_text,_type="close",_icon="info",_title="",handlers = {}) =>{        
        setPopup({            
            status:true,
            message:_text,
            type:_type,
            icon:_icon,
            title:_title,
            Confirm:handlers?.Confirm,
            Accept:handlers?.Accept
        })        
    }

    const LoadingCriptos = ()=> {
        return (
                <div style={
                    {
                        width:"100vw",
                        height:"100vh",
                        position:"fixed",
                        background:"white",
                        top:0,left:0,
                        zIndex:9999,
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        background: "rgb(11,80,3)",
                        background: "radial-gradient(circle, rgba(11,80,3,1) 0%, rgba(108,161,93,1) 30%, rgba(255,255,255,1) 100%)",
                    }}>
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            flexDirection:"column",
                            width:350,
                            height:350,
                            borderRadius:"0%"
                            }}>
                            <img src="/assets/principal/logo_jh_white.png" alt=""  width={"100%"}/>
                            <span style={{color:"white",fontWeight:600,fontSize:25}}>Loading</span>
                        </div>
            </div>
        
       )
    }
    
    return (<Contexto.Consumer>
        {(contexto)=>{
            const {isLoading,globalSession,globalTokens,isLogged,C,PopupCookies,setPopupCookies,LoadCompleted} = contexto
            return(<>
            
            {Popup?.status && <PopUp data={Popup} defaultAction={setPopup}/>}
            <Header  Contexto={Contexto} home menu />
                <div className="pages-home">
                    {!LoadCompleted && <LoadingCriptos />}
                    {PopupCookies && <PopUpCookies cookies={setPopupCookies} />}
                    {isLoading && <Loader />} 
                    
                    <CalculatorAth tokens={globalTokens} />
                    {/*<MyWallet  Contexto={Contexto} ctx={contexto}/>*/}
                    <MyWalletNew ShowPop={ShowPop} />
                    <SwapAth  Contexto={Contexto} />
                    <Ranking   
                        setList={setSinceTotalList} 
                        setOrder={setOrderSince}
                        order={orderSince}
                        tkns={tokens}
                        tknsX={tokensX}
                        list={sinceTotalList}
                        lastUpdate={lastUpdate}
                    />
                    <Footer PopUp={setPopup} />        
                </div>
            </>
            )
            
        }}
    </Contexto.Consumer>)

}


export default Home;
