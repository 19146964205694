import React,{useState,useEffect,useCallback} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import api from "./services/api";
import {
  Home,
  Login,
  Register,
  Legal,
  Cookies,
  Privacity,
  Session,
  Test
} from './pages';
import PopUp from './components/popups/GeneralPopup';
import Constants from './helpers/constants/Constants'
import { isTablet } from 'react-device-detect';
import ReactGA from 'react-ga';
import OneSignal from 'react-onesignal';

//OneSignal.init({ appId: 'b6ad2d71-d5f4-47d5-a904-d32de505d30d' });
const C = new Constants()
export const Global = React.createContext({});



function App() {
  const TRACKING_ID = "G-366F6QSP74"; // OUR_TRACKING_ID
  
  const [Popup,setPopup] = useState({status:false,message:""})
  const [PopupCookies,setPopupCookies] = useState(false)
  const [dark,setDark]  = useState(false)

  const [initialized, setInitialized] = useState(false);

  const IniciarOneSignal = () =>{
    OneSignal.init({ appId: 'b6ad2d71-d5f4-47d5-a904-d32de505d30d', allowLocalhostAsSecureOrigin: true }).then(() => {
      setInitialized(true);
      OneSignal.Slidedown.promptPush();
      // do other stuff
    })
  }




  //REFACTORIZANDO TOKENS PARA PODER ORGANIZAR LAS DIFERENTES 
  //SITUACIONES QUE SE PRESENTAN
  //La lista de tokens cuando no esta logueado, testeo y cambios que no se guardan.
  const [TokenListLocal,setTokenListLocal] = useState(C.DEFAULT_TOKENS);

  //La lista de tokens cuando se intenta crear una wallet desde el popup, vacia 
  //solo inicia con datos si hay datos en la base de datos que no son iguales a la
  //cartera predeterminada de prueba.
  const [TokenListToAdd,setTokenListToAdd] = useState([]);

  //La lista de tokens que obtenemos del usuario desde la base de datos si es que 
  //este esta logueado y tiene algun registro valido
  const [TokenListDB,setTokenListDB] = useState([]);

  //lista de tokens globales solicitados desde la base de datos, sin ningun filtro  especial aplicado
  const [globalTokens,setGlobalTokens] = useState(C.DEFAULT_TOKENS);

  //Esta variable guarda los datos de la sesion de forma global 
  const [globalSession,setGlobalSession] = useState(C.DEFAULT_SESSION);

  //[OBSOLETO]
  const [globalUserTokens,setGlobalUserTokens] = useState([{}]);
  const [userTokensTemp,setUserTokensTemp] = useState([]);

  //aca guardamos el total de mywallet independientemente si es current o ath el valor inicia en 0
  const [globalTotal,setGlobalTotal] = useState(0);
  const [globalTotalAdd,setGlobalTotalAdd] = useState(0);

  //Estas variables ayudan al swap ath con el selector para definir el valor inicial
  const [coinSpawValue,setCoinSpawValue]  = useState(0.5) //valor de inicio 
  const [coinSpawName,setCoinSpawName]  = useState(C.DEFAULT_COIN_SUFIJO) //Abreviatura BTC
  const [coinSpawIcon,setTokenSpawIcon]  = useState(C.DEFAULT_COIN_ICON) //icono
  const [coinSpawLabel,setTokenSpawLabel]  = useState(C.DEFAULT_COIN_LABEL) //Placeholder

  //Con esta manejamos el tipo de precio del calculo de mywallet si es ath o curren
  const [priceType,setPriceType]  = useState(C.DEFAULT_PRICE_TYPE)

  //Para verificar si tiene una session activa
  const [isLogged,setIsLogged]  = useState(false) // inicia falsa para que compruebe al cargar

  //Uso para procesos de precarga de la web
  const [isLoading,setIsLoading]  = useState(true) //iniciamos true para desactivar post-carga
  const [LoadCompleted,setLoadCompleted]  = useState(false) //iniciamos true para desactivar post-carga

  //switch de la version si es beta 
  const [isBeta,setIsBeta]  = useState(true)

  

  //obtener todos los tokens desde la api
  const getTokens = async () => {
    setIsLoading(true)
    await api.get("/test/?function=GetAllToken")
    .then(({data}) => {
      const {content} = data
      //ordenar por mayor capitalizacion de mercado
      //filtrar top 200
      //setGlobalTokens(data.content.sort((a,b)=>b.volume_24h - a.volume_24h ).filter((t,i)=>i<200))
      //console.log("%cTokens actualizados","color:green")
      const tempTokens = content.sort((a,b)=>b.volume_24h - a.volume_24h)
     // console.log(tempTokens)
      setGlobalTokens(tempTokens)
      setIsLoading(false)
    })
    .catch((err) => setIsLoading(false))
  }
  //verificamos si hay sesion activa
  const getSession = async () =>{
    let _username = localStorage.getItem('userSession');
    let _cookies = localStorage.getItem('cookies');
    if(!_cookies){
      setPopupCookies(true)
    } 
    let Temp = {} //helper
    if(_username == "" || _username == undefined || _username == null){
      _username = "local"
      useGAEvent('Invitado','Alguien entro a la home sin registro',`usuario: ${_username}`);
      //console.log("%cno hay usuario logueado","color:orange")
    } else {
      useGAEvent('Usuario con cuenta','Un usuario que ya estaba registrado entro',`usuario: ${_username}`);
    }
    //Temp = await 
    let temp = {} //helper 
    await api.get(C.GetUserURL('verifyUser', _username))
    .then(({data}) => {
      //si no hay resultados se marca como no logueado
      if(data.content.length <= 0){
        //console.log("DEFAULT_SESSION")
        temp = C.DEFAULT_SESSION
        setGlobalSession(temp)
        setIsLogged(false)
      } else {
        //console.log("DATABASE SESSION")
        //console.log()
        temp = data.content[0]
        setGlobalSession(temp)
        setIsLogged(true)
      }
      setIsLoading(false)
    })
    //getUserData(_username)

    //setGlobalSession(Temp)
  }
  
  //verificar si el usuario existe en db y obtener su registro
  const getUserData = async (username) => { 
    let temp = {} //helper 
    setIsLoading(true)
    await api.get(C.GetUserURL('verifyUser', username))
    .then(({data}) => {
      //si no hay resultados se marca como no logueado
      if(data.content.length <= 0){
        //console.log("DEFAULT_SESSION")
        temp = C.DEFAULT_SESSION
        setGlobalSession(temp)
        setIsLogged(false)
      } else {
       // console.log("DATABASE SESSION")
        //console.log()
        temp = data.content[0]
        setGlobalSession(temp)
        setIsLogged(true)
      }
      setIsLoading(false)
    })
    .catch((err) => setIsLoading(false))
    return temp
  }

  //obtener los tokens del usuario obtenidos desde la base de datos
  const setUserTokens = async()=>{
    let temporal = []
    let tempOutput = []
    const {wallets} =  globalSession
    //verificar si existen tokens validos
    if( wallets == ("[]"   || null || "null" || "" || [] ||  undefined)){
      temporal= TokenListLocal
    }else{
      //console.log("wallets")
      //console.log(globalSession)
      //console.log(wallets)
      //console.log(isLogged)
      temporal = isLogged?wallets:wallets
    }
    //actualizando valores de los tokens
    if(temporal != tempOutput){
      globalTokens.map((tkn)=>{
        //console.log(typeof(temporal))
        if(typeof temporal === "string"){
          temporal = Array.from(temporal)
        }
        temporal.map((temp)=>{
          //console.log(temp)
          if(tkn.name == temp.name &&  tkn.sufijo == temp.sufijo){
            temp.ath_date = tkn?.ath_date
            temp.ath_price = tkn?.ath_price
            temp.current_price = tkn?.current_price
            temp.drop_porcent = tkn?.drop_porcent 
            temp.grafica_7d = tkn?.grafica_7d 
            temp.high_porcent = tkn?.high_porcent
            temp.logo = tkn?.logo
            temp.rank_id = tkn?.rank_id
            temp.volume_24h = tkn?.volume_24h
            temp.high_porcent = tkn?.high_porcent
            tempOutput.push(temp)
          }
        })
      })
    }
    
    isLogged?setTokenListDB(tempOutput):setTokenListLocal(tempOutput)
    
  }

  const setUTokenTemp = (tkns)=>{
    setUserTokensTemp(tkns)
  }
  //init
  useEffect(()=>{
    ReactGA.initialize(TRACKING_ID);


    if(!initialized){
      IniciarOneSignal()
    }

    window.document.body.style['overflow-y'] = "hidden"
    getSession()
    getTokens()
    setUserTokens()
    const timer1 = setTimeout(() => {
      //window
      window.document.body.style['overflow-y'] = "auto"
      setLoadCompleted(true)
    }, 1000)
    return ()=>{clearTimeout(timer1)}
  },[])

  //walle se actualiza si hay algun cambio en los tokens o sesion
  useEffect(()=>{
    setUserTokens()
    return ()=>{}
  },[globalTokens,isLogged])

  //actualizar el total por cualquier cambio en las wallets
  useEffect(()=>{
    sumarWallet()
    
    return ()=>{}
  },[TokenListDB,TokenListLocal,TokenListToAdd,priceType])

useEffect(()=>{
  sumarWalletAdd()
},[TokenListToAdd])



  //manejador para cambiar la cantidad en cada token de mywallet
  const handlerChangeCant = (_nombre,_sufijo,_cantidad)=>{
    let temporal = [] //helper para intercambiar los tokens segun la sesion
    if (!isLogged) temporal = TokenListLocal //cuando la lista es test
    if (isLogged &&  TokenListDB != C.DEFAULT_TOKENS) temporal = TokenListDB
    temporal.map(({name,sufijo},index)=>{
      if(name === _nombre && sufijo === _sufijo){
        temporal[index].cantidad = parseFloat(_cantidad)
      }
    })
    isLogged?setTokenListDB(temporal):setTokenListLocal(temporal)
  }

  //actualizar la wallet del usuario loggeado
  const updateSession = async( )=>{
    if(!isLogged)return
    const data = { 
      ...globalSession,
      function:"updateUser",
      wallets:TokenListDB
    }
    const headers = {headers: {'Content-Type': 'multipart/form-data'}}
    await api.post("/test/?function=updateUser",data,headers)
    .then((res2) => {

      ShowPop("User "+globalSession.username+" updated" )
    })
    .catch((err) => console.error("ops!" + err))
  }
  const SaveTokensOnRegister = async (_user)=>{
      let walletsLS =  localStorage.getItem('wallets-temp')
      if(walletsLS == null || walletsLS == undefined || walletsLS == "" ){
        walletsLS = "[]" 
      }
      let WalletsToSave = Array.from(walletsLS)
      const data = { 
          username:_user,
          function:"updateUser",
          wallets : WalletsToSave
        }
        const headers = {headers: {'Content-Type': 'multipart/form-data'}}
        await api.post("/test/?function=updateUser",data,headers)
        .then((res2) => {
          //alert("usuario "+globalSession.username+" actualizado" )
        })
        .catch((err) => console.error("ops!" + err))
        //setReload(!Reload)
  }

  //eliminar un token de la lista 
  const handlerDelToken = (_nombre,_sufijo)=>{
    
    if(TokenListDB.length <= 0){
      setTokenListDB(C.DEFAULT_TOKENS)
    } else {
      setTokenListDB(TokenListDB.filter((item)=>item.name == _nombre && item.sufijo == _sufijo))
    }
    
  }
  const handlerDelAddToken = (_nombre,_sufijo)=>{
    
    if(TokenListDB.length <= 0){
      setTokenListToAdd([])
    } else {
      setTokenListToAdd(TokenListToAdd.filter((item)=>item.name == _nombre && item.sufijo == _sufijo))
    }
    
  }
  //setear el token favorito en el swap ath para pasarle los datos.(boton de estrella)
  const handlerFavorito = (_nombre,_sufijo,_cantidad,icon)=>{
    setCoinSpawValue(_cantidad)
    setTokenSpawLabel(_nombre)
    setCoinSpawName(_sufijo)
    setTokenSpawIcon(icon)
  }

  //agregar un nuevo token a la lista de tokens de addwallet
  const handlerAddWallet = async(nuevoToken) =>{
    const {name,sufijo,cantidad} = nuevoToken
    /console.log("antes")
    //primero lo eliminamos si existe y luego le
    const existe =  TokenListToAdd.filter((token)=>token.name === name && token.sufijo === sufijo)
    //console.log("a ver si existe")
    //console.log(existe)
    if(existe.length > 0){
      //console.log("existe")
      const temp = TokenListToAdd
      temp.map((tkn,i)=>{
        if(tkn.name == name && tkn.sufijo == sufijo){
          temp[i].cantidad = cantidad
        }
      })
      setTokenListToAdd(temp)
      sumarWalletAdd()
    } else {
      //console.log("no existe")
      setTokenListToAdd([...TokenListToAdd,nuevoToken])
      sumarWalletAdd()
    }
  }
  //
  const handlerClearWallet = () =>{
    isLogged?setTokenListDB(C.DEFAULT_TOKENS):setTokenListLocal(C.DEFAULT_TOKENS)
  }
  const handlerPrice = (value) =>{
    setPriceType(value)
  }
  const handlerDarkMode = ()=>{
    setDark(!dark)
  }
  const onCloseSession = () =>{
    //ShowPop('do you want to log out?',true)
    ShowPop(
      'Do you want to log out?',
      "cancel confirm",
      "alert",
      "Attention",
      {Confirm:()=>{
          //changeMode(0);
          closeSession();
          setPopup({status:false,message:"",type:"",icon:"",title:""})
      }}
  )
  }
  const closeSession = () =>{    
    localStorage.removeItem('userSession');
    window.location.replace("/");
  }
  const PopUpTablet = ( ) => {        
    return (<>
    <div className="pop-add tablet" >
        <div className="pop-add-content message-pop">
            <h2>Sorry, it is currently not available for iPad and/or tablet, you can use Justhodl from a computer or phone.</h2>      
            <div className="pop-exit-div">
                
            </div>
        </div>
    </div>
    </>)
  }
  //se encarga de sumar los totales de my wallet independientemente el tipo de valor
  const sumarWallet = () =>{
    let total = 0 //helper para acumular
    let tokens = [] //helper para intercambiar los tokens segun la sesion
    if (!isLogged) tokens = TokenListLocal //cuando la lista es test
    if (isLogged &&  TokenListDB != C.DEFAULT_TOKENS) tokens = TokenListDB

    for (const property in tokens){
      priceType=="ath"
      ?
        total += tokens[property]?.cantidad * tokens[property]?.ath_price 
      :
        total += tokens[property]?.cantidad * tokens[property]?.current_price
    }
    setGlobalTotal(total)
  }
   //se encarga de sumar los totales de add wallet independientemente el tipo de valor
  const sumarWalletAdd = () =>{
    let total = 0 //helper para acumular
    for (const property in TokenListToAdd){
        total += TokenListToAdd[property]?.cantidad * TokenListToAdd[property]?.ath_price 
    }
    setGlobalTotalAdd(total)
  }

  const ShowPop = (_text,_type="close",_icon="info",_title="",handlers = {}) =>{        
    setPopup({            
        status:true,
        message:_text,
        type:_type,
        icon:_icon,
        title:_title,
        Confirm:handlers?.Confirm,
        Accept:handlers?.Accept
    })        
}




const StaticScroll = (value) =>{
  if(value){

      window.document.body.style['overflow-y'] = "auto"
  } else {
      window.document.body.style['overflow-y'] = "auto"
  }
}
  
  const gaEventTracker = useGAEvent('Home Page','Enter in the homepage');


  return (
    <Global.Provider value={{
      gaEventTracker,
      globalTokens,
      globalSession,
      TokenListDB,
      TokenListLocal,
      TokenListToAdd,
      globalTotal,
      priceType,
      coinSpawValue,
      coinSpawName,
      coinSpawIcon,
      coinSpawLabel,
      isLogged,
      isLoading,
      C,
      isBeta,
      dark,
      userTokensTemp,
      globalTotalAdd,
      PopupCookies,
      LoadCompleted,
      ShowPop,
      onCloseSession,
      updateSession,
      handlerChangeCant,
      handlerDelToken,
      handlerFavorito,
      handlerAddWallet,
      handlerClearWallet,
      handlerPrice,
      handlerDarkMode,
      setCoinSpawValue,
      setUTokenTemp,
      sumarWalletAdd,
      setTokenListToAdd,
      SaveTokensOnRegister,
      setTokenListLocal,
      StaticScroll,
      useGAEvent,
      setPopupCookies
    }}>
      <BrowserRouter>
        <div>
          {isTablet && <PopUpTablet />}
          {Popup?.status && <PopUp data={Popup} defaultAction={setPopup}/>}
          {!isTablet &&           
          <Routes>
            <Route path="/" element={<Home  Contexto={Global}/>} />
            <Route path="/home" element={<Home  Contexto={Global}/>} />
            <Route path="/legal" element={<Legal  Contexto={Global}/>} />
            <Route path="/cookies" element={<Cookies  Contexto={Global}/>} />
            <Route path="/privacity" element={<Privacity  Contexto={Global}/>} />
            <Route path="/session" element={<Session  Contexto={Global}/>} />
            <Route path="/login" element={<Login  Contexto={Global}/>} />
            <Route path="/register" element={<Register  Contexto={Global}/>} />
            <Route path="/test" element={<Test  Contexto={Global}/>} />
            <Route path="*" element={<Home  Contexto={Global}/>} />
          </Routes>
          }
        </div>
      </BrowserRouter>
    </Global.Provider>
  );
}

export const useGAEvent = (category="sin Categoria",_action="sin accion",_label="sin label") => {
  const eventTracker = (action = _action, label = _label) => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default App;