import React, { useState,useRef, useEffect } from "react";
import  "./myWallet.css";
import  "./myWallet_responsive.css";
import { isMobile } from 'react-device-detect';
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../helpers'
import {faSave,  faTrash,faStar,faPencil} from "@fortawesome/free-solid-svg-icons";
import styled  from "styled-components";
import Select  from 'react-select';
import {Link } from 'react-router-dom';
import Title from "../title";
import {Global} from '../../App'

const Button = styled.button`
    background:white;
    cursor: pointer;
    border:solid 2px ${(props)=>(props.border ||'green')};
    font-size: 16px;
    padding: 5px 30px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    background: ${(props)=>(props.bg ||'white')};
    color: ${(props)=>(props.color ||'green')};
    margin: 5px;
    
    &:hover{
        color:${(props)=>(props.bg ||'white')};
        background:${(props)=>(props.color ||'green')};
        border:solid 2px ${(props)=>(props.border ||'green')};
    }
    @media only screen and (max-width:480px){
        height: ${(props)=>(props.height || '500' )};
    }
`
const LinkA = styled(Link)`
    color:green;
    background:white;
    border:solid 2px green;
    font-size: 18px;
    padding: 10px 40px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    &:hover{
        color:white;
        background:green;
        border:solid 2px green;
    }
`
//aqui iniicia el nuevo componente
const Total = ({total,priceType}) =>{
    if(priceType=='current'){
        return (<div  className="comp-total">
            <span>Total value USD</span> 
            <b>${DollarFormat(total)}</b>
        </div>)
    } 
    return(<div  className="comp-total">
            <span>Total value ATH </span>
            <b>${DollarFormat(total)}</b>
        </div>)
    
}
const ButtonsToMyWallet = ({handlerSetPop,handlerPrice,priceType,handlerClearWallet,isLogged}) =>{
    ////console.log('%c Comp <BUTTONSMYWALLET  />','color:red')
    const handlerClose = (event) =>{
        handlerSetPop(event.target.getAttribute('data-value'))
    }
    const handlerSetPrice = (event) =>{
        handlerPrice(event.target.getAttribute('data-value'))

    }
    const ClearHistory = (event) =>{
        handlerClearWallet()
    }
    const Aviso = () =>{
        return(<div className="aviso">
            <span>This is a test wallet</span>
        </div> )
    }
   
    return (<div className="buttons-my-wallet">
        <div className="btn-section-1">
            <button data-value={true}  className={"btn-add-wallet"} onClick={handlerClose}> Add Wallet</button>
            
        </div>
        <div className="btn-section-0">
            {!isLogged && <Aviso />}
        </div>
        
        <div className="btn-section-2">
            <Button 
                bg={priceType=='ath'?'white':'green'} 
                color={priceType=='ath'?'green':'white'} 
                border={'black'}  
                data-value={'current'} 
                onClick={handlerSetPrice}>
                Current Value
            </Button>
           
            <Button 
                bg={priceType=='ath'?'green':'white'} 
                color={priceType=='ath'?'white':'green'} 
                border={'black'}  
                data-value={'ath'} 
                onClick={handlerSetPrice}>
                Value in ATH
            </Button>
            
            <Button bg={'white'} color={'gray'} border={'gray'}  onClick={ClearHistory}>
                Reset Wallet
            </Button>
        </div>        
</div>)
}
const HeadAthMyWallet= (side,priceType) =>{
    if(side==1){
        return (<>
            <div className="comp-athRow-title ath-user mobile-name">
                
                
                <div className="div-criptoData div-center ">
                    <span>CRYPTO</span>
                </div>
            </div>
        </>);
    } else if(side==2){
        return (<>
            <div className="comp-athRow-title ath-user mobile-data">
                <div className="div-currentPrice div-center">
                    <span>CURRENT PRICE</span>
                </div>
                <div className="div-currentPrice div-center">
                    <span>TOKENS</span>
                </div>
                
                <div className="div-athPrice div-center">
                    <span>ATH</span>
                </div>
                <div className="div-dropPorcent div-center">
                    <span>TO HIGH</span>
                </div>
                <div className="div-dataAth div-center">
                    <span>DATE ATH</span>
                </div>
                <div className="div-dropPorcent div-center">
                    <span>PRICE DROP ATH</span>
                </div>
                <div className="div-volume24 div-center">
                    {priceType=='current'?<span>VALUE USD</span>:<span>VALUE ATH</span>}
                </div>
            </div>
        </>);
    } else {
        return (<>
            <div className="comp-wallet-title">
                <div className="div-title-star">
                    {new Helpers().Icon(faStar,'star-icon-2')}
                </div>
                <div className="div-title-crypto border-left">
                    <span>CRYPTO</span>
                </div>
                <div className="div-title-current border-left">
                    <span>CURRENT PRICE</span>
                </div>
                <div className="div-title-tokens border-left">
                    <span>TOKENS</span>
                </div>
                <div className="div-title-ath border-left">
                    <span>ATH</span>
                </div>
                <div className="div-title-high border-left">
                    <span>TO HIGH</span>
                </div>
                <div className="div-title-date border-left">
                    <span>DATE ATH</span>
                </div>
                <div className="div-title-drop border-left">
                    <span>PRICE DROP ATH</span>
                </div>
                <div className="div-title-total border-left">
                    {priceType=='current'?<span>CURRENT VALUE</span>:<span>VALUE ATH</span>}
                </div>
                <div className="div-title-del border-left">
                    <div className="del-button">
                       
                    </div>
                </div>
            </div>
        </>);
    }
    
}
const HeadAthMyWalletPop= (side,priceType) =>{
    
    return (<>
        <div className="comp-wallet-title pop-title">
            <div className="div-title-star">
                {new Helpers().Icon(faStar,'star-icon-2')}
            </div>
            <div className="div-title-crypto border-left">
                <span>CRYPTO</span>
            </div>
            <div className="div-title-current border-left">
                <span>CURRENT PRICE</span>
            </div>
            <div className="div-title-tokens border-left">
                <span>TOKENS</span>
            </div>
            <div className="div-title-total border-left">
                {priceType=='current'?<span>CURRENT VALUE</span>:<span>VALUE ATH</span>}
            </div>
            <div className="div-title-del border-left">
                <div className="del-button">
                    
                </div>
            </div>
        </div>
    </>);
    
}
const Wallet = ({contexto,tkn}) =>{
    const {
        handlerChangeCant,
        handlerDelToken,
        handlerFavorito,
        priceType,isLogged
    } = contexto
    const {
        cantidad,
        ath_price,
        ath_date,
        name,
        sufijo,
        id,
        logo,
        grafica_7d,
        drop_porcent,
        high_porcent,
        current_price
    } = tkn
    const ref_input = useRef()
    const [editable,setEditable] = useState(false)
    const [expand,setExpand] = useState(false)
    const manejadorDel = ()=>{
        handlerDelToken(name,sufijo)
    }
    const manejadorFavorito = () =>{
        const element = document.getElementById('comp-swap');
        if (element) {
           element.scrollIntoView({ behavior: 'smooth' });
           element.scrollIntoView({block: "end", behavior: 'smooth' });
        }
        handlerFavorito(sufijo,sufijo,ref_input.current.value,logo)
        //setCoinSpawValue(ref_input.current.value)
    }
    const handlerValue = () =>{
        if(editable){
            handlerChangeCant(name,sufijo,ref_input.current.value)
            setEditable(false)
            return
        } 
        setEditable(true)
    }
    const Minimizado = () =>{
        return(<>
            <div  className="comp-my-wallet minimized">
                <div className="section-1-wallet">
                    <div className="div-wallet-star">
                        {new Helpers().Icon(faStar,"star-icon")}
                        <span className="span-wallet-id">{id}</span>
                    </div>
                    <div className="div-wallet-crypto">
                        <span>Crypto</span>
                        <div className="inner-wallet-crypto">
                            <div className="cripto-logo-coin">
                                <img alt='' src={logo} width={20} height={20} />
                            </div>
                            <div className="cripto-names">
                                <div className="cripto-name"> <b>{name}/{sufijo}</b></div>
                            </div>
                        </div>
                    </div>
                    <button className="more" onClick={handlerExpand}>
                        <span>---</span>
                    </button>
                </div>
                <div className="section-2-wallet">
                
                    <div className="div-wallet-inner" >
                        <span>Tokens</span>
                        <div  className="div-wallet-tokens minimizado">
                            <input 
                                className="input-tokens-wallet"
                                ref={ref_input}
                                type={"number"}
                                defaultValue={cantidad}
                                disabled={!editable}
                                placeholder={0.0000}
                                >
                            </input>
                            <div className="edit-button" onClick={handlerValue}>
                                {new Helpers().Icon(!editable?faPencil:faSave,"edit-icon")}
                            </div>
                        </div>
                    </div>
                    <div className="div-wallet-total" >
                        <span>Value ATH</span>
                        <b>${DollarFormat(priceType=="ath"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }</b>
                    </div>
                    <div className="div-wallet-del">
                            <button className="buton-del" onClick={manejadorDel} >
                                {new Helpers().Icon(faTrash,'trash-icon')}
                            </button>
                        </div>
                </div>
                
            </div>
        </>)
    }
    const Maximizado = () =>{
        return(<>
            <div  className="comp-my-wallet">
                <div className="section-1-wallet">
                    <div className="div-wallet-star">
                        {new Helpers().Icon(faStar,"star-icon")}
                        <span className="span-wallet-id">{id}</span>
                    </div>
                    <button className="more" onClick={handlerExpand}>
                        <span>-|-</span>
                    </button>
                </div>
                <div  className="section-2-wallet">
                    <div className="div-wallet-crypto">
                        <span>Crypto</span>
                        <div className="inner-wallet-crypto">
                            <div className="cripto-logo-coin">
                                <img alt='' src={logo} width={20} height={20} />
                            </div>
                            <div className="cripto-names">
                                <div className="cripto-name"> <b>{name}/{sufijo}</b></div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="div-wallet-current ">
                        <span>Current Price</span>
                        <span className="current-value">${DollarFormat(current_price)}</span> 
                    </div>
                </div>
                <div className="section-3-wallet">
                <div className="div-wallet-inner" >
                        <span>Tokens</span>
                        <div  className="div-wallet-tokens minimizado">
                            <input 
                                className="input-tokens-wallet"
                                ref={ref_input}
                                type={"number"}
                                defaultValue={cantidad}
                                disabled={!editable}
                                placeholder={0.0000}
                                >
                            </input>
                            <div className="edit-button" onClick={handlerValue}>
                                {new Helpers().Icon(!editable?faPencil:faSave,"edit-icon")}
                            </div>
                        </div>
                    </div>
                    <div className="div-wallet-current ">
                        <span>ATH</span>
                        <span className="current-value">${DollarFormat(ath_price)}</span> 
                    </div>
                </div>
                <div className="section-4-wallet">
                    <div className="div-wallet-current ">
                        <span>To High</span>
                        <span className="current-value"><b> {ToX(high_porcent,1)}</b></span> 
                    </div>
                    <div className="div-wallet-current ">
                        <span>Date ATH</span>
                        <span className="current-value"> {ath_date}</span> 
                    </div>
                </div>
                <div className="section-5-wallet">
                    
                        <span>Price Drop ATH</span>
                        <img alt='' className="grafica" src={grafica_7d} height={30} />
                        <span>{drop_porcent}%</span>
                </div>
                <div className="section-6-wallet">
                    <div className="div-wallet-total" >
                        <span>Value ATH</span>
                        <b>${DollarFormat(priceType=="ath"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }</b>
                    </div>
                    <div className="div-wallet-del">
                        <button className="buton-del" onClick={manejadorDel} >
                            {new Helpers().Icon(faTrash,'trash-icon')}
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    const handlerExpand = () =>{
        setExpand(!expand)
    }

    if(isMobile){
        return (<>
            {!expand && <Minimizado />}
            {expand && <Maximizado />}
        </>)
    }
    return (
        <div  className="comp-my-wallet">
            <div className="div-wallet-star">
                <button
                    className="favorite"
                    onClick={manejadorFavorito}
                >
                    {new Helpers().Icon(faStar,"star-icon")}
                    <span className="span-wallet-id">{id}</span>
                </button>      
            </div>
            <div className="div-wallet-crypto  border-left-2">
                <div className="cripto-logo-coin">
                    <img alt='' src={logo} width={30} height={30} />
                </div>
                <div className="cripto-names">
                    <div className="cripto-name"> <b><span>{name} /</span></b></div>
                </div>
                <div className="cripto-names">
                    <div className="cripto-sufijo">{sufijo}</div>
                </div>
            </div>
            <div className="div-wallet-current  border-left-2">
                <span>${DollarFormat(current_price)}</span> 
            </div>
            <div  className="div-wallet-tokens  border-left-2">
                <input 
                    className="input-tokens-wallet"
                    ref={ref_input}
                    type={"number"}
                    defaultValue={cantidad}
                    disabled={!editable}
                    placeholder={0.0000}
                    >
                </input>
                <div className="edit-button" onClick={handlerValue}>
                    {new Helpers().Icon(!editable?faPencil:faSave,"edit-icon")}
                </div>
            </div>
            <div className="div-wallet-ath  border-left-2">
                <span>${DollarFormat(ath_price)}</span>
            </div>
            <div className="div-wallet-high  border-left-2">
                <b> {ToX(high_porcent,1)}</b>
            </div>
            <div className="div-wallet-date  border-left-2">
                {ath_date}
            </div>
            <div className="div-wallet-drop  border-left-2">
                <div className='div-drop p-p'>
                    <div style={{flexDirection:'column',display:"flex"}}>
                        <span>{drop_porcent}%</span>
                        <img alt='' className="grafica" src={grafica_7d} height={30} />
                    </div>
                </div>
            </div>
            <div className="div-wallet-total  border-left-2">
                ${DollarFormat(priceType=="ath"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }
            </div>
            <div className="div-wallet-del  border-left">
                {isLogged && <button className="buton-del" onClick={manejadorDel} >
                    {new Helpers().Icon(faTrash,'trash-icon')}
                </button>}
            </div>
        </div>
   )
}
const WalletPop = ({contexto,tkn,handlerChangeCant,handlerDelAddToken}) =>{
    const {isLogged} = contexto
    const {cantidad,
        ath_price,
        name,
        sufijo,
        id,
        logo,
        current_price} = tkn
    const ref_input = useRef()
    const [editable,setEditable] = useState(false)
    const [expand,setExpand] = useState(false)
    
    const manejadorDel = ()=>{
        handlerDelAddToken(sufijo)
    }
    const handlerValue = () =>{
        if(editable){
            handlerChangeCant(name,sufijo,ref_input.current.value)
            setEditable(false)
            return
        } 
        setEditable(true)
    }
   
    
    return (
        <div  className="comp-my-wallet">
            <div className="div-wallet-star">
                <button
                    className="favorite"
                >
                    {new Helpers().Icon(faStar,"star-icon")}
                    <span className="span-wallet-id">{id}</span>
                </button>      
            </div>
            <div className="div-wallet-crypto  border-left-2">
                <div className="cripto-logo-coin">
                    <img alt='' src={logo} width={30} height={30} />
                </div>
                <div className="cripto-names">
                    <div className="cripto-name"> <b><span>{name} /</span></b></div>
                </div>
                <div className="cripto-names">
                    <div className="cripto-sufijo">{sufijo}</div>
                </div>
            </div>
            <div className="div-wallet-current  border-left-2">
                <span>${DollarFormat(current_price)}</span> 
            </div>
            <div  className="div-wallet-tokens  border-left-2">
                <input 
                    className="input-tokens-wallet"
                    ref={ref_input}
                    type={"number"}
                    value={cantidad}
                    disabled={!editable}
                    placeholder={0.0000}
                    >
                </input>
                <div className="edit-button" onClick={handlerValue}>
                    {new Helpers().Icon(!editable?faPencil:faSave,"edit-icon")}
                </div>
            </div>
            <div className="div-wallet-total  border-left-2">
                ${DollarFormat(cantidad * ath_price || 0)}
            </div>
            <div className="div-wallet-del  border-left">
                {<button className="buton-del" onClick={manejadorDel} >
                    {new Helpers().Icon(faTrash,'trash-icon')}
                </button>}
                
            </div>
        </div>
   )
}
const SaveChanges =  ({saveSession,isLogged}) =>{
    if(!isLogged){
        return (<div id="comp-my-wallet-finish" className="comp-save">
            <LinkA  to="/Register" >SAVE YOUR WALLET</LinkA>
            </div>)
    }
    return (<div id="comp-my-wallet-finish" className="comp-save">
        <LinkA onClick={saveSession}>SAVE YOUR CHANGES</LinkA>
        </div>)
}
const SaveChangesPop =  ({saveSession,isLogged,setTokenTemp}) =>{
    if(!isLogged){
        return (<div id="comp-my-wallet-finish" className="comp-save">
            <LinkA  onClick={setTokenTemp} to="/Register" >SAVE YOUR WALLET</LinkA>
            </div>)
    }
    return (<div id="comp-my-wallet-finish" className="comp-save">
        <LinkA onClick={saveSession}>SAVE YOUR CHANGES</LinkA>
        </div>)
}
const AddWalletComponent = ({contexto,handlerAddToken}) =>{
    const {globalTokens,handlerAddWallet,isLogged,TokenListToAdd,ShowPop} = contexto
    const [addWallet,setAddWallet] = useState({})
    const [tokenIdAddWallet,setTokenIdAddWallet]  = useState('')
    const [tokenAddWallet,setTokenAddWallet]  = useState('BTC')
    const [tokenLabelAddWallet,setTTokenLabelAddWallet]  = useState('Select a token')
    const [tokenIconAddWallet,setTokenIconAddWallet]  = useState('./assets/img/coin/64x64/1.png')
    const [opciones,setOpciones] = useState([]) //opciones del selector
    const [value,setValue] = useState(1)
    const select_add = useRef();
    ////console.log('%c Comp <ADDWALLETCOMPONENT  />','color:red')
    
    
    const setearOpciones = async() =>{
        const options = [];
        await globalTokens.map((e)=>{
            options.push({
                label : `${e.name} (${e.sufijo})`, 
                value : `${e.id}`,
                logo : `${e.logo}`,
                id : `${e.id}`,
                ath_price : e.ath_price,
                current_price : (e.current_price),
                sufijo : e.sufijo,
                ath_date : e.ath_date,
                name  :e.name,
                drop_porcent : e.drop_porcent,
                grafica_7d : e.grafica_7d,
                high_porcent : e.high_porcent,
                rank_id : e.rank_id,
                cantidad:e.cantidad
            })
            
        })

        setOpciones(options);
    }
    //definir el btc como valor inicial
    const defaulOption  = async()=>{
        const e = globalTokens[0]
        setAddWallet({
            label : `${e.name} (${e.sufijo})`, 
            value : `${e.id}`,
            logo : `${e.logo}`,
            id : `${e.id}`,
            ath_price : e.ath_price,
            current_price : (e.current_price),
            sufijo : e.sufijo,
            ath_date : e.ath_date,
            name  :e.name,
            drop_porcent : e.drop_porcent,
            grafica_7d : e.grafica_7d,
            high_porcent : e.high_porcent,
            rank_id : e.rank_id,
            cantidad:e.cantidad
        })
    }

    useEffect(()=>{
        defaulOption();
        setearOpciones();
    },[])
    useEffect(()=>{
        setearOpciones();
    },[globalTokens])


    const handlerWallet = ()=>{
        //console.log("reproducir")
        if(value <= 0 ){
            ShowPop('The value cannot be zero',"close","error","Something is wrong")
            return
        }
        let walletToAdd = {
            id:addWallet.id,
            name:addWallet.name,
            sufijo:addWallet.sufijo,
            cantidad:parseFloat(value),
            ath_date:addWallet.ath_date,
            ath_price:addWallet.ath_price,
            current_price:addWallet.current_price,
            drop_porcent:addWallet.drop_porcent,    
            grafica_7d:addWallet.grafica_7d,
            high_porcent:addWallet.high_porcent,
            logo:addWallet.logo,
            rank_id:addWallet.rank_id,
            volume_24h:addWallet.volume_24h
        }
        handlerAddWallet(walletToAdd)
    }
    const HandlerSelectorAdd = (e) =>{
        setTokenIconAddWallet(e.logo)
        setTokenAddWallet(e.sufijo)
        setTTokenLabelAddWallet(e.label)
        setTokenIdAddWallet(e.id)
        setAddWallet(e)
    }
    const handlerChange = (e) => {
        if(e?.nativeEvent.data === '-'){
            return
        }
        if(parseInt(select_add.current.value) < 0){
            setValue(0)
        } else {
            setValue(Math.abs(select_add.current.value))
        }
    }
    return(<>
    <div className="comp-add-wallet">
            <div className="token-add">
                <div className="search-name-add-wallet" key={'selector-wallet-001'}>   
                    <img className="add-img-wallet" src={tokenIconAddWallet} height={20} width={20} />
                    <Select 
                        className="select-add-wallet"
                        isSearchable
                        onChange={HandlerSelectorAdd}
                        options={opciones} 
                        placeholder={tokenLabelAddWallet}
                        defaultValue={{label: "BTC", value: "1"}}
                    />
                </div>
                <div className="add-token-div">
                    <span className="token-title">TKNs</span>
                    <input 
                        ref={select_add}
                        className="token-input-add"
                        type="number"
                        min={0}
                        value={value}
                        onChange={handlerChange}
                        onPaste={handlerChange}
                        placeholder={TokenFormat(0.0000)}
                    /> 
                </div>
                <button className="button-add"onClick={handlerWallet}>
                    ADD TO WALLET
                </button>
            </div>
    </div>
    </>)
}
const AddWalletsPop = ({handlerSetPop,priceType,contexto}) => {
    const {
        globalSession,
        updateSession,
        isLogged,
        TokenListToAdd,
        setTokenListToAdd,
        globalTotalAdd
    } = contexto
    const handlerClose = () =>{
        if(!isLogged)setTokenListToAdd([])
        handlerSetPop(false)
    }
    
   
    const handlerAddToken = (newToken) =>{
        let temp = true
        TokenListToAdd.map((ltkn)=>{
            if(ltkn.sufijo == newToken.sufijo){
                temp = false
            }
        })
        if(temp){
            setTokenListToAdd([...TokenListToAdd,newToken])
            
        }
    }
    useEffect(()=>{
        if(isLogged){
            setTokenListToAdd(TokenListToAdd);
        } else {
            setTokenListToAdd([])
        }
    },[isLogged])

    useEffect(()=>{
        setTokenListToAdd(TokenListToAdd.sort((a,b)=>{
            return priceType=='ath'
            ?
            ((b.cantidad * b.ath_price) - (a.cantidad * a.ath_price)) 
            :
            ((b.cantidad * b.current_price) - (a.cantidad * a.current_price)) 
        }))
    },[TokenListToAdd])

    const handlerDelAddToken = (sufijo) =>{
        const temp = TokenListToAdd.filter((tkn)=>tkn.sufijo !== sufijo)
        setTokenListToAdd(temp.sort((a,b)=>{
            return priceType=='ath'
            ?
            ((b.cantidad * b.ath_price) - (a.cantidad * a.ath_price)) 
            :
            ((b.cantidad * b.current_price) - (a.cantidad * a.current_price)) 
        }))
        
    }
    const handlerChangeCant = (nombre,sufijo,valor) =>{
        let temp = TokenListToAdd
        temp.map((tkn,index)=>{
            if(tkn.name == nombre && tkn.sufijo == sufijo){
                //console.log(tkn)
                temp[index].cantidad =  parseInt(valor) 
            }
        })
        setTokenListToAdd(temp.sort((a,b)=>{
            return priceType=='ath'
            ?
            ((b.cantidad * b.ath_price) - (a.cantidad * a.ath_price)) 
            :
            ((b.cantidad * b.current_price) - (a.cantidad * a.current_price)) 
        }))
       // sumarWallet()
    }


    return (<div className="pop-add" >
        <div className="pop-add-content">
            <button  onClick={handlerClose} className="pop-exit">x</button>
            <Title  title={"MY WALLET"}  icon={'wallet-icon-green'}   subtitle={"Add new tokens to your wallet"} />
            <AddWalletComponent contexto={contexto} handlerAddToken={handlerAddToken} />
            
            <div className="my-wallets-zoom">

                {!isMobile && TokenListToAdd.lenght > 0 && HeadAthMyWalletPop(3,priceType)}
                <div className="my-wallet-scroll add-comp">
                    
                    {TokenListToAdd.map((tkn)=>{
                        return (
                            <WalletPop  
                                key={"mywallet-123"+tkn?.name+tkn?.sufijo}
                                tkn={tkn} 
                                handlerChangeCant={handlerChangeCant} 
                                handlerDelAddToken={handlerDelAddToken}
                                priceType={priceType}
                                contexto={contexto}
                            />
                        )
                    })}
                </div>
                <Total total={globalTotalAdd} priceType={priceType}/>
                <SaveChangesPop 
                    isLogged={isLogged} 
                    session={globalSession} 
                    saveSession={updateSession} 
                    onClick={handlerClose}
                />
            </div>
        </div>
        
    </div>)
}


///componente principal
const MyWallet = ({ctx}) => {
    //console.log(Contexto)
    const {TokenListDB,TokenListLocal,globalTotal} = ctx;
    const [tokenListTemp,setTokenListTemp] = useState([])
    const [IsLocalLoading,setIsLocalLoading] = useState(true)
    
    const [pop,setPop]  = useState(false)
    const handlerSetPop = (value) =>{
        setPop(value)
    }

    useEffect(()=>{
        setTokenListTemp(ordenarTokens())
    },[TokenListLocal,TokenListDB,globalTotal])


    const ordenarTokens = () =>{
        setIsLocalLoading(true)
        const { 
            TokenListLocal,
            priceType,
            isLogged,
            TokenListDB,
        } = ctx
        let temp = []
        isLogged?temp = TokenListDB : temp = TokenListLocal
       const temporal =  temp.sort((a,b)=>{
            if(priceType=="ath"){
                if((b.cantidad * b.ath_price)  == (a.cantidad * a.ath_price) ) {return 0;}
                if((b.cantidad * b.ath_price)  < (a.cantidad * a.ath_price) ) {return -1;}
                return 1;
            } else {
                if((b.cantidad * b.current_price)  == (a.cantidad * a.current_price) ) {return 0;}
                if((b.cantidad * b.current_price)  < (a.cantidad * a.current_price) ) {return -1;}
                return 1;
            } 
        })
        setIsLocalLoading(false)
        return temporal
     }

    return (<Global.Consumer>
        {(contexto)=>{
            const {
                globalTotal,
                globalSession,
                updateSession,
                handlerClearWallet,
                priceType,
                handlerPrice,
                isLogged,
                isLoading,
                C
            } = contexto
           
            return (<div id="comp-wallet" className="comp-wallet">
                {pop && <AddWalletsPop 
                            handlerSetPop={handlerSetPop}
                            priceType={priceType}
                            contexto={contexto}
                        />}
                       
                <Title  
                    title={C.SECTION_MYWALLET_TITLE} 
                    icon={C.SECTION_MYWALLET_ICON} 
                    subtitle={C.SECTION_MYWALLET_SUBTITLE}
                />
               
                <ButtonsToMyWallet 
                    handlerPrice={handlerPrice} 
                    handlerSetPop={handlerSetPop}
                    handlerClearWallet={handlerClearWallet}
                    priceType={priceType}
                    isLogged={isLogged}
                />
                {!isMobile && HeadAthMyWallet(3,priceType)}
                <div className={"my-wallet-scroll " + (IsLocalLoading && "cargando")}>
                    {tokenListTemp.map((tkn)=><Wallet  key={"mywallet-123"+tkn?.id} tkn={tkn}  contexto={contexto}/>)}
                </div>
                <Total total={globalTotal} priceType={priceType}/>
                <SaveChanges 
                    isLogged={isLogged} 
                    session={globalSession} 
                    saveSession={updateSession} 
                />
            </div> )
        }}
    </Global.Consumer>)
}

export default MyWallet
