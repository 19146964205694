import axios from "axios";
const api = axios.create({
  baseURL: "https://justhodl.io/api/v2/",
});
// baseURL: "https://athmywallet.urmah.dev/api/v1/",
export default api;

// Verbo GET
//api.get(endpoint)

// Verbo POST
// api.post(endpoint, dados)

// Verbo DELETE
//api.delete(endpoint, dados)

// Verbo PUT
// api.put(endpoint, dados)