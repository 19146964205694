import React, { useState,useRef, useEffect } from "react";
import styled  from "styled-components";
import {Contexto} from '../../../pages/test/Test'
import Constants from '../../../helpers/constants/Constants'
import { useGAEvent } from '../../../App';
const C = new Constants()
const Button = styled.button`
    background:white;
    cursor: pointer;
    border:solid 2px ${(props)=>(props.border ||'green')};
    font-size: 16px;
    padding: 5px 30px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    background: ${(props)=>(props.bg ||'white')};
    color: ${(props)=>(props.color ||'green')};
    margin: 5px;
    
    &:hover{
        color:${(props)=>(props.bg ||'white')};
        background:${(props)=>(props.color ||'green')};
        border:solid 2px ${(props)=>(props.border ||'green')};
    }
    @media only screen and (max-width:480px){
        height: ${(props)=>(props.height || '500' )};
    }
`
//aqui iniicia el nuevo componente
export const ButtonsToMyWallet = ({contexto,setPop}) =>{
    const {setTypePrice,TypePrice,IsLogged,setTokenListLocal,setTokenListDB,StaticScroll} = contexto
    ////console.log('%c Comp <BUTTONSMYWALLET  />','color:red')
    const [TPrice,setTPrice] = useState("ATH")

    useEffect(()=>{
        if(TypePrice=="ATH" || TypePrice=="CURRENT"){
            setTPrice(TypePrice)
        }
    },[TypePrice])


    const handlerPop = (event) =>{
        setPop(event.target.getAttribute('data-value'))
        StaticScroll(event.target.getAttribute('data-value'))
        useGAEvent("Add Wallet","Se abrio el popup para crear cartera")
    }
    const handlerSetPrice = (event) =>{
        setTPrice(event.target.getAttribute('data-value'))
        setTypePrice(event.target.getAttribute('data-value'))
    }
    const ClearHistory = (event) =>{
        /*if(IsLogged){
            return
        }*/
        if(confirm("Are you sure to reset your wallet?")){
            //IsLogged?setTokenListDB(C.DEFAULT_TOKENS):setTokenListLocal(C.DEFAULT_TOKENS)
            //handlerChangeCant()
        }
    }
    const Aviso = () =>{
        return(<div className="aviso">
            <span>This is a test wallet</span>
        </div> )
    }
   
    return (<div className="buttons-my-wallet">
        <div className="btn-section-1">
            <button data-value={true}  className={"btn-add-wallet"} onClick={handlerPop}> Add Wallet</button>
        </div>
        <div className={"btn-section-0 " + (IsLogged?"":"withAviso")}>
            {!IsLogged && <Aviso />}
        </div>
        
        <div className="btn-section-2">
            <Button 
                className="valueinath"
                bg={TPrice=='ATH'?'white':'green'} 
                color={TPrice=='ATH'?'green':'white'} 
                border={'black'}  
                data-value={'CURRENT'} 
                onClick={handlerSetPrice}>
                Current Value
            </Button>
           
            <Button 
                className="valueinath"
                bg={TPrice=='ATH'?'green':'white'} 
                color={TPrice=='ATH'?'white':'green'} 
                border={'black'}  
                data-value={'ATH'} 
                onClick={handlerSetPrice}>
                Value in ATH
            </Button>
            
            {/*<Button bg={'white'} color={'gray'} border={'gray'}  onClick={ClearHistory}>
                Reset Wallet
            </Button>*/}
        </div>        
</div>)
}

