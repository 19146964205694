import React, { useEffect, useState } from "react";
import {SiteLogo} from '../../components'
import '../../App.css';
import './footer.css';
import './footer_responsive.css';
import {Link } from 'react-router-dom';
import Constants from '../../helpers/constants/Constants'
import { isMobile } from 'react-device-detect';
const C = new Constants()
function Footer({PopUp}) {
    const handlerSocial = (event) =>{

        switch(event.target.getAttribute('data-social')){
            case 'twitter' : window.open('https://twitter.com/youjusthodl', '_blank');break;
            case 'instagram' : window.open('https://www.instagram.com/justhodl.io', '_blank');break;
            case 'telegram' : window.open('https://msng.link/o?justhodl=tg', '_blank');break;
            default :break;
        }
        
    }
    const handlerPopUp = () => {
        PopUp({
            
            status:true,
            message:"Email <br> daniel@justhodl.io",
            type:"accept",
            icon:"info",
            title:"Contact"
        })
    }
    return (
        <>
            <div id="footer">
                <div className="footer-row row1">
                    <div className="footer-column column-1">
                        <div className="footer-inner-row">
                        <SiteLogo logo={C.SITE_LOGO_WHITE} height={100}/>
                        </div>
                        <div className="footer-inner-row">
                            <p className="parrafo-01">
                            The content of this website as well as the information available on it, are to provide you with general
                            information from external sources. This information is automatically updated every hour. We are not
                            responsible for any possible inaccuracies or errors. This information is in no case financial advice and its
                            sole purpose is informative and didactic and must be used with the total responsibility of the user. We
                            rely on the total transparency of the information so that the user makes their own responsible decisions
                            and can obtain different perspectives, but taking into account that the crypto market is a high-risk
                            market. If in doubt about what decisions to make, please consult a certified financial advisor.
                            </p> 
                        </div>
                    </div>
                    <div className="footer-column column-3">
                        <div className="footer-inner-row">
                            <span>SOCIAL MEDIA</span>
                        </div>
                        <br></br>
                        <div className="footer-inner-row social-media">
                            <img 
                                src="https://justhodl.io/assets/principal/instagram.png" 
                                width={40}
                                data-social={'instagram'}
                                onClick={handlerSocial}
                            />
                            <img 
                                src="https://justhodl.io/assets/principal/twitter.png" 
                                width={40}
                                data-social={'twitter'}
                                onClick={handlerSocial}
                            />
                           
                        </div>
                    </div>
                    <div className="footer-column column-4">
                        <div className="footer-inner-row">
                            <span>Coming soon</span>
                            <br></br>
                            <div className="botones-descarga">
                                <img src="https://justhodl.io/assets/principal/appstore.png" width={120}/>
                                <br />
                                <img src="https://justhodl.io/assets/principal/playstore.png" width={120}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="footer-row row2">
                    <Link key={'link008'}   onClick={()=>window.location.replace('/legal/')} >
                        {<span>LEGAL NOTICE </span>}
                        
                    </Link>
                    <Link key={'link009'}   onClick={()=>window.location.replace('/cookies/')} >
                        {!isMobile &&  <span>| COOKIES</span>}
                        {isMobile &&  <span>COOKIES</span>}
                    </Link>
                    <Link key={'link010'}    onClick={()=>window.location.replace('/privacity/')} >
                         {!isMobile &&  <span> | PRIVACY POLICY</span>}
                         {isMobile &&  <span>PRIVACY POLICY</span>}
                    </Link>
                    <Link key={'link011'}  to="" onClick={handlerPopUp} >
                         {!isMobile &&  <span> | CONTACT</span>}
                         {isMobile &&  <span>CONTACT</span>}
                    </Link>
                    <span>© 2023 JUSTHODL. All Rights Reserved.</span>
                </div>
            </div>
        </>
      
    );
  }
  
  export default Footer;