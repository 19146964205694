import React, { useEffect, useState } from "react";
import './ranking.css';
import './ranking_responsive.css';
import {DollarFormat,ToX,Helpers} from '../../helpers'
import {Title} from "../../components"
import {faSave,  faTrash,faStar,faPencil,faClock,faClockFour} from "@fortawesome/free-solid-svg-icons";
import { useGAEvent } from '../../App';
import { isMobile } from "react-device-detect";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
const Ranking = (props) =>{
    const [showTopBtn, setShowTopBtn] = useState(false);
    const titulo = 'Ranking ATH'
    const icono = 'rank-icon-green'
    const subtitulo = 'Discover the change in a cryptocurrency’s ranking since its’ ATH.'
    const handlerScroll = () =>{
       if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll',handlerScroll);
        return ()=>window.removeEventListener('scroll',handlerScroll);
    }, []);
    const handlerSince = (event) =>{
        props.setOrder(event.target.getAttribute('data-value'))
    }
    
    const handlerGoBottom = () =>{
        //console.log('bottom')
        const element = document.getElementById("footer");
        let pos = element.offsetTop;
        window.scrollTo({
            top: pos,
            behavior: 'smooth',
        });
    }
    const CargarMasSince = () =>{
        
        const handlerCargarTodo = (event) =>{
            useGAEvent("Ranking","Se solicito la lista completa del ranking")
            props.setList(200)
        }
        const handlerResetTop = (event) =>{
            useGAEvent("Ranking","Se solicito volver al top12 del ranking")
            props.setList(12)
        }
        return(<>
            <div className="load-more-section">
                {
                    props?.list < 190  &&
                    props?.list > 0  &&
                    <button className="button-load-more" onClick={handlerCargarTodo}>
                       <span>{ "Show more  >"}</span>
                    </button>
                }
                {
                    props?.list > 12&&
                    <button className="button-load-more" onClick={handlerResetTop}>
                        RESET TOP   12
                    </button>
                }
    
            </div>
        </>)
    }

    const Bottom  = () =>{
        return (<><div className="go-bottom" onClick={handlerGoBottom}>↓</div></>)
    }
    const CriptoSinceATH = () =>{
       // console.log('%c Comp <CRIPTOSINCEATH  />','color:red')
        let contador = 0;
        let arrayTokenSince = [];
        switch(props.order){
            case 'high':arrayTokenSince = props.tknsX.filter((a,i)=>i<200).slice(); break;
            case 'capital': arrayTokenSince = props.tkns.sort((a,b)=>a.rank_id - b.rank_id).filter((a,i)=>i<200).slice(); break;
            case 'ath': break;
            default: arrayTokenSince = props.tkns; break;
        }
       // console.log(props.list)
       const desktopStyles = {display:"flex",alignItems:"flex-start",gap:"10px"}
       const CurrentYear = new Date().getFullYear()
       const tooltipstyle = { 
            backgroundColor: "green", 
            color: "#fff",
            width:"150px",
            minWidth:"150px",
            maxWidth:"50vw",
            zIndex:10000,
            fontSize:"14px",
            borderRadius:"10px",
            opacity:1,
            textTransform:"none",
            fontWeigth:600
        }
        return(<div className="div-sinces">
            { arrayTokenSince.map((e)=>{
                for(var i=0;i<arrayTokenSince.length;i++){
                    contador++;
                    const DropPorcentReal = (((e.current_price * 100 )/e.ath_price) - 100).toFixed(2)
                    //const HighPorcentReal = (e.ath_price /e.current_price).toFixed(2)
                    const HighPorcentReal =  (e.ath_price/e.current_price).toFixed(2)
                    if(contador <= props.list){
                        return (<div className="element-ranking"  key={`div-athRo-${e.id}-${e.sufijo}`}>
                            <div className="logo-name" >
                                <div className="cripto-logo-coin">
                                    <img src={e.logo} width={30} height={30} />
                                </div>
                                <div className="cripto-names">
                                    <div className="cripto-sufijo">{e.sufijo}</div>
                                </div>
                            </div >
                            <div className="derecha">
                                <div  className="arriba">
                                    <div className="high-porcent">
                                        <span className="" style={isMobile?{}:desktopStyles}>
                                            
                                            <span>{DropPorcentReal + "%"}</span>
                                            {
                                                e?.ath_date?.includes(CurrentYear.toString()) 
                                                && 
                                                <>
                                                    <img 
                                                        src={"/assets/img/DiamontJustHodl_1.png"} 
                                                        width={30} 
                                                        height={30} 
                                                        data-tooltip-id={"my-tooltip-ath-" + e.sufijo}
                                                        data-tooltip-content={`New ATH in ${CurrentYear}`}
                                                        data-tooltip-place="bottom"
                                                    />
                                                    <Tooltip  id={"my-tooltip-ath-" + e.sufijo}   style={tooltipstyle} />
                                                 </>
                                            }
                                        </span>
                                        <div className="porcent">
                                            <div className="line" style={{width:`${Math.abs(DropPorcentReal)}%`}}>
                                            </div>
                                            <div className="circle" style={{marginLeft:`${Math.abs(DropPorcentReal)}%`}}>
                                                <div className="inner-circle">
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="since-x">
                                        {ToX(e.high_porcent,1) }
                                    </div>
                                </div>
                                <div  className="abajo">
                                    <div className="price-value">
                                        
                                    <span>CURRENT: ${DollarFormat(e.current_price)} </span>   
                                    </div>
                                    <div className="ath-value">
                                        <span>ATH: ${DollarFormat(e.ath_price)}</span>
                                    </div>
                                </div>

                            </div>
                            
                            
                        </div>
                        )
                    }
                }
            })}
        </div>)
    }
    const TopButtons = () =>{
       return ( <div className="div-since-buttons">
                <button 
                    data-value={'high'}
                    className={props.order=='high'?'selected-since':'no-selected-since'}
                    onClick={handlerSince}
                >
                    Order By High
                </button>
                <button 
                    data-value={'capital'}
                    className={props.order=='capital'?'selected-since':'no-selected-since'}
                    onClick={handlerSince}
                >
                    Order By Cap
                </button>
            </div>)
    }
    const LastUpdate = () =>{
        return(<div className="last-update">
            {new Helpers().Icon(faClockFour,"star-icon")}
            <span>Last update {props.lastUpdate} minutes ago</span>
        </div>);
    }
    return (<>
        <div id="comp-since-ath" className="comp-since-ath">
            <Title 
                title={titulo} 
                icon={icono} 
                subtitle={subtitulo}
                info={"Access our ATH ranking, which ranks cryptocurrencies based on their current value, their ATH and their growth potential, or margin (X), in descending order, with those with the highest market capitalisation at the top. Change the ranking order to sort via X, to have the cryptocurrencies with the highest margin to their ATH at the top; those that may have the highest return."}
                label={"ranking"}
            />
            {showTopBtn && <Bottom />}
            <TopButtons />
            <CriptoSinceATH />
            <LastUpdate />
            <CargarMasSince />
        </div>
    </>)
}

export default Ranking;
