import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Helpers extends React.Component {
    constructor(props){
        super(props)
    }

    Icon = (_icon,_class) => {
        return (<FontAwesomeIcon className={_class} icon={_icon} />)
    }
    PreventMinus(e){
        if(e.code === 'Minus'){
            e.preventDefault();
        }
    }
    PreventPasteNegative(e){
        const clipboardData = e.clipboardData || window.clipboardData;    
        const pastedData = parseFloat(clipboardData.getData('text'));
        if (pastedData < 0) {
            e.preventDefault();
        }
    }
    NotEmpty(string){
        if(string != '' && string != undefined && string != null ){
            return true;
        }
        return false;
    }
}




export default Helpers;