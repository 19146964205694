import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Card, SiteLogo,FormularioLogin,Footer} from '../../components';
import '../../App.css';
import './legal.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEyeSlash,faEye,faLock,faEnvelope,faBell} from "@fortawesome/free-solid-svg-icons";
import { data } from "jquery";
import {Link } from 'react-router-dom';
import { useLocation, Navigate } from 'react-router-dom';
import { useGAEvent } from '../../App';

function Legal({Contexto}) {
    const [Popup,setPopup] = useState(false)
    return (<>
        <Header Contexto={Contexto} home menu/>
        <div className="form-legal-notice" >
                <br />
                <div style={{color:"black",display:"flex",flexDirection:"column",alignItems:"center",width:"100%",background:""}}>
                    <h3>Legal Notice</h3>
                    <p>
                        The information contained in this website is for general information purposes only. 
                        The information is provided by Justhodl.io and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. 
                        Any reliance you place on such information is therefore strictly at your own risk.
                    </p>
                    <p>
                        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                    </p>
                    <p>
                        Through this website you are able to link to other websites which are not under the control of Justhodl.io. 
                        We have no control over the nature, content and availability of those sites. 
                        The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                    </p>
                    <p>
                        Every effort is made to keep the website up and running smoothly. However, Justhodl.io takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    </p>
                    <p>
                        This website is not affiliated with, endorsed by, or sponsored by any of the cryptocurrency platforms or services mentioned on this website. 
                        All trademarks and logos belong to their respective owners. The use of such names and logos on this website is for identification purposes only and does not imply any association with or endorsement by them.</p>
                    <p>
                        The content of this website is not intended to provide financial, investment, legal, tax, or any other kind of advice. 
                        You should not rely on the information on this website as an alternative to professional advice from your accountant, lawyer, financial planner, or other qualified professional. 
                        You should consult your own advisors before making any decision based on the information on this website.
                    </p>
                    <p>
                        Justhodl.io does not offer any kind of guarantee or warranty on the performance, profitability, legality, or suitability of any cryptocurrency investment or strategy. You are solely responsible for your own research, analysis, and risk assessment before engaging in any cryptocurrency-related activity. 
                        You should never invest more than you can afford to lose.
                    </p>
                    <p>
                        By using this website, you agree to indemnify and hold harmless Justhodl.io and its owners, operators, contributors, partners, affiliates, and agents from any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys’ fees) that such parties may incur as a result of or arising from your (or anyone using your account’s) use of this website or violation of these terms and conditions.

                    </p>
                    <p>
                        Justhodl.io reserves the right to change these terms and conditions at any time without prior notice. 
                        By continuing to use this website after any changes are made, you agree to be bound by the updated terms and conditions. 
                        It is your responsibility to check this page periodically for any changes.
                    </p>
                    <p>
                        If you have any questions about this legal notice or our website in general, please contact us at <a href="mailto:support@justhodl.io">support@justhodl.io</a>
                    </p>
                    <p>Last updated: October 30th 2023</p>
                   
                </div>

        </div>
        <div className="legal-back-to-home">
            <Link key={'link012'}   style={{color:"green",marginTop:20}} onClick={()=>window.location.replace('/')}>
                <b >GO BACK TO HOME</b>
            </Link>
        </div>
        
        <Footer PopUp={setPopup} />   
        <div style={{marginBottom:50}}></div>
        </>);
  }
  
  export default Legal;