import React from "react";
import './title.css';
import './title_responsive.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const Title = ({title,icon,subtitle,info,label})=>{
    return (<>
    <div className="comp-title">
        <div id="section-title-id" className="section-title" style={{display:"flex", alignItems:"center"}}>
            <img src={'./assets/principal/'+icon+".png"} height={20}/>
            <span className="title-title">{title}</span>
            <FontAwesomeIcon 
                className="icon-information" 
                data-tooltip-id={"my-tooltip-" + label}
                data-tooltip-content={info || "Hello world!"}
                data-tooltip-place="bottom"
                icon={faInfoCircle} style={{marginLeft:"10px",fontSize:"20px",color:"green",cursor:"pointer"}} 
            />
           
            <Tooltip 
                id={"my-tooltip-" + label} 
                style={{ 
                    backgroundColor: "#dddddd", 
                    color: "#222",
                    width:"500px",
                    minWidth:"300px",
                    maxWidth:"80vw",
                    zIndex:10000,
                    fontSize:"14px",
                    borderRadius:"10px",
                    opacity:1,
                    textTransform:"none" 
                }}
            />
            
        </div>
        <div className="section-subtitle">
            <span className="subtitle-title">{subtitle}</span>
        </div>
    </div>
    </>)
}
export default Title;