import React, { useState,useRef, useEffect } from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import Select  from 'react-select';
import {Contexto} from '../../../pages/test/Test'
import { isMobile } from 'react-device-detect';
//aqui iniicia el nuevo componente
export const AddWallet = ({contexto}) =>{
    const {GlobalTokens,handlerAddWallet,ShowPop} = contexto
    const [addWallet,setAddWallet] = useState({})
    const [tokenIdAddWallet,setTokenIdAddWallet]  = useState('')
    const [tokenAddWallet,setTokenAddWallet]  = useState('BTC')
    const [tokenLabelAddWallet,setTTokenLabelAddWallet]  = useState('Select a token')
    const [tokenIconAddWallet,setTokenIconAddWallet]  = useState('./assets/img/coin/64x64/1.png')
    const [opciones,setOpciones] = useState([]) //opciones del selector
    const [value,setValue] = useState(1)
    const select_add = useRef();
    ////console.log('%c Comp <ADDWALLETCOMPONENT  />','color:red')
    
     
    const setearOpciones = async() =>{
        const options = [];
        if(GlobalTokens.length <= 0)return console.log("no hay tokens")
        GlobalTokens.map((e)=>{
            options.push({
                label : `${e.name} (${e.sufijo})`, 
                value : `${e.id}`,
                logo : `${e.logo}`,
                id : `${e.id}`,
                ath_price : e.ath_price,
                current_price : (e.current_price),
                sufijo : e.sufijo,
                ath_date : e.ath_date,
                name  :e.name,
                drop_porcent : e.drop_porcent,
                grafica_7d : e.grafica_7d,
                high_porcent : e.high_porcent,
                rank_id : e.rank_id,
                cantidad:e.cantidad
            })
            
        })

        setOpciones(options);
    }
    //definir el btc como valor inicial
    const defaulOption  = async()=>{
        const e = GlobalTokens[0]
        if(GlobalTokens.length <= 0)return console.log("no hay tokens")
        setAddWallet({
            label : `${e.name} (${e.sufijo})`, 
            value : `${e.id}`,
            logo : `${e.logo}`,
            id : `${e.id}`,
            ath_price : e.ath_price,
            current_price : (e.current_price),
            sufijo : e.sufijo,
            ath_date : e.ath_date,
            name  :e.name,
            drop_porcent : e.drop_porcent,
            grafica_7d : e.grafica_7d,
            high_porcent : e.high_porcent,
            rank_id : e.rank_id,
            cantidad:e.cantidad
        })
    }

    useEffect(()=>{
        defaulOption();
        setearOpciones();
    },[])
    useEffect(()=>{
        setearOpciones();
    },[GlobalTokens])


    const handlerWallet = ()=>{
        console.log("reproducir")
        if(value <= 0 ){
            ShowPop('The value cannot be zero',"close","error","Something is wrong")
            return
        }
        let walletToAdd = {
            id:addWallet.id,
            name:addWallet.name,
            sufijo:addWallet.sufijo,
            cantidad:parseFloat(value),
            ath_date:addWallet.ath_date,
            ath_price:addWallet.ath_price,
            current_price:addWallet.current_price,
            drop_porcent:addWallet.drop_porcent,    
            grafica_7d:addWallet.grafica_7d,
            high_porcent:addWallet.high_porcent,
            logo:addWallet.logo,
            rank_id:addWallet.rank_id,
            volume_24h:addWallet.volume_24h
        }
        handlerAddWallet(walletToAdd)
    }
    const HandlerSelectorAdd = (e) =>{
        setTokenIconAddWallet(e.logo)
        setTokenAddWallet(e.sufijo)
        setTTokenLabelAddWallet(e.label)
        setTokenIdAddWallet(e.id)
        setAddWallet(e)
    }
    const handlerChange = (e) => {
        if(e?.nativeEvent.data === '-'){
            return
        }
        if(parseInt(select_add.current.value) < 0){
            setValue(0)
        } else {
            setValue(Math.abs(select_add.current.value))
        }
    }
    return(<>
    {isMobile && 
        <div className="comp-add-wallet">
            <div className="token-add">
                <div className="token-add-col-1">
                    <span>Select a token</span>
                    <div className="token-add-search">   
                        <img className="add-img-wallet" src={tokenIconAddWallet} height={20} width={20} />
                        <Select 
                            className="select-add-wallet"
                            isSearchable
                            onChange={HandlerSelectorAdd}
                            options={opciones} 
                            placeholder={tokenLabelAddWallet}
                            defaultValue={{label: "BTC", value: "1"}}
                        />
                    </div>
                </div>
                <div className="token-add-col-2">
                    <span>Token amount</span>
                    <div className="add-token-amount">
                        <input 
                            ref={select_add}
                            className="token-input-add"
                            type="number"
                            min={0}
                            value={value}
                            onChange={handlerChange}
                            onPaste={handlerChange}
                            placeholder={TokenFormat(0.0000)}
                        /> 
                    </div>
                </div>
            </div>
            <div className="token-add-col-3">
                <button className="button-add"onClick={handlerWallet}>
                    ADD TO WALLET
                </button>
            </div>
        </div>}
    {!isMobile && 
        <div className="comp-add-wallet">
                <div className="token-add">
                    <div className="token-add-col-1">
                        <span>Select a token</span>
                        <div className="token-add-search">   
                            <img className="add-img-wallet" src={tokenIconAddWallet} height={20} width={20} />
                            <Select 
                                className="select-add-wallet"
                                isSearchable
                                onChange={HandlerSelectorAdd}
                                options={opciones} 
                                placeholder={tokenLabelAddWallet}
                                defaultValue={{label: "BTC", value: "1"}}
                                style={{zIndex:1000}}
                            />
                        </div>
                    </div>
                    <div className="token-add-col-2">
                        <span>Token amount</span>
                        <div className="add-token-amount">
                            <input 
                                ref={select_add}
                                className="token-input-add"
                                type="number"
                                min={0}
                                value={value}
                                onChange={handlerChange}
                                onPaste={handlerChange}
                                placeholder={TokenFormat(0.0000)}
                            /> 
                        </div>
                    </div>
                    <div className="token-add-col-3">
                        <button className="button-add"onClick={handlerWallet}>
                            ADD TO WALLET
                        </button>
                    </div>
                </div>
        </div>
    }
    </>)
}