import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
function Loader() {
    const [user, setUser] = useState();
    return (
        <>
        <img src={logo} className="App-logo" alt="logo" />
        </>
      
    );
  }
  
  export default Loader;