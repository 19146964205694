import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {MyWalletTest} from '../../components';
import '../../App.css';
import './Test.css';
import './Test_responsive.css';
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import Constants from '../../helpers/constants/Constants'
import {Global} from '../../App'
const C = new Constants()
export const Contexto = React.createContext({});
Contexto.displayName = 'ContextoGlobal';
import { useGAEvent } from '../../App';
const Test = ({ShowPop}) =>{
    
    //Definicion de variables de estado

    //La lista de tokens cuando no esta logueado, testeo y cambios que no se guardan.
    const [TokenList,setTokenList] = useState([])

    //La lista de tokens cuando no esta logueado, testeo y cambios que no se guardan.
    const [TokenListLocal,setTokenListLocal] = useState(C.DEFAULT_SESSION.wallets)

    //La lista de tokens cuando se intenta crear una wallet desde el popup, vacia 
    //solo inicia con datos si hay datos en la base de datos que no son iguales a la
    //cartera predeterminada de prueba.
    const [TokenListToAdd,setTokenListToAdd] = useState([])

    //La lista de tokens que obtenemos del usuario desde la base de datos si es que 
    //este esta logueado y tiene algun registro valido
    const [TokenListDB,setTokenListDB] = useState([])

    //aca guardamos el total de mywallet independientemente si es current o ath el valor inicia en 0
    const [GlobalTotal,setGlobalTotal] = useState(0)

    //aca guardamos el total de mywallet independientemente si es current o ath el valor inicia en 0
    const [GlobalTotalPop,setGlobalTotalPop] = useState(0)

    //lista de tokens globales solicitados desde la base de datos, sin ningun filtro  especial aplicado
    const [GlobalTokens,setGlobalTokens] = useState([])

    //Para verificar si tiene una session activa
    const [IsLogged,setIsLogged]  = useState(false) // inicia falsa para que compruebe al cargar

    //Uso para procesos de precarga de la web
    const [IsLoading,setIsLoading]  = useState(true) //iniciamos true para desactivar post-carga

    //tipo de precio si es ath o current
    const [TypePrice,setTypePrice]  = useState("ATH") 

    //Esta variable guarda los datos de la sesion de forma global 
    const [globalSession,setGlobalSession] = useState([]);


    //variable para actualizar forzadamente
    const [Reload,setReload] = useState(false)

    //inicio de las funciones
    //verificamos si hay sesion activa
    const GetSesion = async () =>{
        let _username = localStorage.getItem('userSession');
        let Temp = {} //helper
        if(Vacio(_username)){
            _username = "local"
        }
        Temp = await GetUserData(_username)
        setGlobalSession(Temp)
        VerifyWalletUser()
    }
    //verificar ahora si la sesion tiene algun dato
    const VerifyWalletUser = () =>{
        if(!Vacio(globalSession,"array")){
            if(globalSession.username != "local"){
                if(!Vacio(globalSession.wallets)){
                    setTokenListDB(JSON.parse(globalSession.wallets))
                   //Consola("tiene")/
                } else {
                   // Consola("vacio")
                }
            } else {
                setTokenListDB(globalSession.wallets)
               // Consola("es local")
            }
        }else {
            setTokenListLocal(globalSession.wallets)
            //Consola("no hay sesion")
        }
        
    }
    
    //verificar si el usuario existe en db y obtener su registro
    const GetUserData = async (username) => { 
        let temp = {} //helper 
        setIsLoading(true)
        await api.get(GetUserURL('verifyUser',username))
        .then(({data}) => {
        //si no hay resultados se marca como no logueado
        if(data.content.length <= 0){
            temp = C.DEFAULT_SESSION
            setIsLogged(false)
        } else {
            temp = data.content[0]
            setIsLogged(true)
            
        }
        setIsLoading(false)
        })
        .catch((err) => setIsLoading(false))
        //console.log("temp")
        //console.log(temp)
        return temp
    }
    //obtener los tokens 
    const GetTokens = async () => {
        //llamada a la axios para obtener todos los tokens desde la base de datos
        await api.get('/test/?function=GetAllToken')
        //primera respuesta se setea en globalTokens
        .then(({data}) => setGlobalTokens(data.content))
        //si existe algun error se muestra en consola
        .catch(Error)
    }
    //seleccionar cual lista se mostrara
    const SetLista = () =>{
        //Consola("La sesion ha cambiado","black","white")
        setTokenList(IsLogged?TokenListDB:TokenListLocal)
        IsLogged?setTokenListToAdd(TokenListDB):setTokenListToAdd([])
        //console.log(IsLogged?TokenListDB:TokenListLocal)
    }
    //borrar elemento de la lista
    const Borrar = (e) =>{
        const _id = e.target.dataset.index
        setTokenList(TokenList.filter((a)=>a.id != _id))
    }
    //borrar elemento de la lista pop
    const BorrarPop = (e) =>{
        const _id = e.target.dataset.index
        setTokenListToAdd(TokenListToAdd.filter((a)=>a.id != _id))
    }
    const UpdateTokenList = () =>{
        let Temp = [] //variable temporal array
        let Token = {} //variable temporal object
        const Lista = IsLogged?TokenListDB:TokenListLocal
        //recorremos todos los tokens en busca de los listados
        GlobalTokens.map((tknA)=>{
            //limpiamos la variable de token temporal
            Token = {}
            //recorremos la lista local
            Lista.map((tknB)=>{
                //igualamos el elemento del map con el temporal
                Token = tknB
                //si la id actual existe en la lista de tokens
                if(tknA.id === tknB.id){
                    //actualizar precio actual
                    Token.current_price = tknA.current_price 
                    //actualizar precio ath
                    Token.ath_price = tknA.ath_price 
                    //actualizar fecha ath
                    Token.ath_date = tknA.ath_date 
                    //actualizar capitalizacion mercado
                    Token.volume_24h = tknA.volume_24h 
                    //actualizar high porcentaje
                    Token.high_porcent = tknA.high_porcent 
                    //actualizar drop porcentaje
                    Token.drop_porcent = tknA.high_porcent 
                    //actualizar rank id
                    Token.rank_id = tknA.rank_id 
                    //intruducir en variable temporal
                    Temp.push(Token)
                }
            })
        })
        //despues que todo el recorrido termine se setea
        setTokenList(Temp)
        setTotals()
    }/*
    const onChangeValue = (id,value = 0) => {
        console.log("onChangeValue")
        const Temp = TokenList
        TokenList.map((tkn,idx)=>{
            if(tkn.id == id){
                Temp[idx].cantidad = value
            }
        })
        
        setTokenList(Temp)
        setTotals()
    }
    const onChangeValuePop = (id,value = 0) => {
        console.log("onChangeValuePop")
        const TempPop = TokenListToAdd
        TokenListToAdd.map((tkn,idx)=>{
            if(tkn.id == id){
                TempPop[idx].cantidad = value
            }
        })
        setTokenListToAdd(TempPop)
        setTotalsPop()
    }*/
    const onChangeValue = (id, value = 0) => {
        const temp = TokenList.slice()
        temp.forEach((tkn, idx) => {
            if (tkn.id === id) {
                temp[idx] = { ...tkn, cantidad: value }
            }
        });
        setTokenList(temp)
        setTotals()
    };

    const onChangeValuePop = (id, value = 0) => {
        const temp = TokenListToAdd.slice()
        temp.forEach((tkn, idx) => {
            if (tkn.id === id) {
                temp[idx] = { ...tkn, cantidad: value }
            }
        });
        setTokenListToAdd(temp)
        setTotalsPop()
    }


    const SaveSession = async (mode)=>{
        let WalletsToSave = []
        if(mode=="register"){
            ///obtener desde el local storage
            const WLS = localStorage.getItem('wallets-temp')
            if(WLS == null || WLS == undefined || WLS == "" ){
                WalletsToSave = Array.from("[]")
            } else {
                WalletsToSave = Array.from(WLS)
            }
            
        }
        if(!IsLogged)return
        
        if(mode=="pop"){
            WalletsToSave = TokenListToAdd
        }
        if(mode=="home"){
            WalletsToSave = TokenList
        }

        const data = { 
            ...globalSession,
            function:"updateUser",
            wallets : WalletsToSave
          }
          const headers = {headers: {'Content-Type': 'multipart/form-data'}}
          await api.post("/test/?function=updateUser",data,headers)
          .then((res2) => {
            ShowPop("User "+ globalSession.username+" updated","close","info","Information")
          })
          .catch((err) => console.error("ops!" + err))
          setReload(!Reload)
    }
    const handlerAddWallet = (wallet) =>{
        //console.log(wallet)
        setTokenListToAdd([...TokenListToAdd.filter((t)=>t.id != wallet.id), wallet])
        //setTokenListToAdd([...TokenListToAdd, wallet])
        setTotalsPop()
        //console.log("se logo")
    }
    const setTotalsPop = () =>{
        let TotalTempPop = 0

        TokenListToAdd.map((tkn)=>{
            if(TypePrice=="ATH"){
                TotalTempPop += tkn.cantidad * tkn.ath_price
            } else{
                TotalTempPop += tkn.cantidad * tkn.current_price
            }
        })
        setGlobalTotalPop(TotalTempPop)
    }
    const setTotals = () =>{
        let TotalTemp = 0

        TokenList.map((tkn)=>{
            if(TypePrice=="ATH"){
                TotalTemp += tkn.cantidad * tkn.ath_price
            } else{
                TotalTemp += tkn.cantidad * tkn.current_price
            }
        })

        setGlobalTotal(TotalTemp)
    }
    //controlador de ejecucion y cambio de estado de los componentes
    useEffect(()=>{
        //configuramos la lista de tokens local o database
        GetSesion()
    },[IsLogged,Reload])

    useEffect(()=>{
        //configuramos la lista de tokens local o database
        VerifyWalletUser()
    },[globalSession])
    

    useEffect(()=>{
        //configuramos la lista de tokens local o database
        SetLista()
    },[IsLogged,TokenListDB,TokenListLocal,GlobalTokens])

    useEffect(()=>{
        //obtenemos los tokens en la primera llamada y los manenemos en solicitud continua
        GetTokens()
        //se inicia un interval para actualizar los tokens
        const ciclo = Intervalo(GetTokens,900000);
        return(()=>clearInterval(ciclo))
    },[])
    
    useEffect(()=>{
        //se actualizan los precios y demas de la lista de tokens locales
        //console.log(TokenList)
        UpdateTokenList()
    },[TokenListDB,TokenListLocal,GlobalTokens])

    useEffect(()=>{
        setTotalsPop()
    },[TokenListToAdd,TypePrice])

    useEffect(()=>{
        setTotals()
    },[TokenList,TokenListDB,TokenListLocal,TypePrice])



    const StaticScroll = (value) =>{
        if(value){
      
            window.document.body.style['overflow-y'] = "auto"
        } else {
            window.document.body.style['overflow-y'] = "auto"
        }
      }



    //formulacion de las variables del contexto
    const variables = {
        IsLoading,IsLogged,TokenListLocal,TokenListToAdd,
        TokenListDB,GlobalTotal,GlobalTokens,TypePrice,
        TokenList,GlobalTotalPop
    }
    //formulacion de las funciones del contexto
    const funciones = {
        setIsLoading,setTokenListLocal,setTokenListToAdd,
        setTokenListDB,setGlobalTotal,setTypePrice,Borrar,
        setIsLogged,setTokenList,onChangeValue,handlerAddWallet,
        BorrarPop,onChangeValuePop,SaveSession,setGlobalTotalPop,
        ShowPop,StaticScroll,useGAEvent
    }
    //formulacion de las funciones del contexto
    const helpers = {}

    //renderizado de la pagina
    return (
    <Contexto.Provider value={{...variables,...funciones,...helpers}}>
           
                <MyWalletTest />
    </Contexto.Provider>)
}


//HELPERS INTERNOS
//muestra los errores en consola
const Error = (error,color=null,fondo=null,peso=null,size=null) =>{
    return console.error("%c"+error,`color:${color};background:${fondo};font-weight:${peso};font-size:${size}`)
}
//muestra datos en consola
const Consola = (data,color=null,fondo=null,peso=null,size=null) =>{
    return console.log("%c"+data,`color:${color};background:${fondo};font-weight:${peso};font-size:${size}`)
}
//crea un intervalo pasandole la funcion y tiempo en segundos
const Intervalo = (_function,_time) => {
    return setInterval(_function, _time);
}

//vacio
const Vacio = (campo,tipo="texto") =>{
    let Temp = campo
    if(Temp == null)return true
    if(Temp == undefined)return true
    if(tipo == "texto"){
        if(Temp == "")return true
        if(Temp == " ")return true
    }
    if(tipo == "array"){
        if(Temp == [])return true
        if(Temp == "[]")return true
        if(Temp == "[{}]")return true
    }
    if(tipo == "json"){
        if(Temp == {})return true
        if(Temp == "{}")return true
    }
    if(tipo == "int"){
        if(Temp == 0)return true
    }
    if(tipo == "float"){
        if(Temp == 0)return true
    }
    //si pasa todas las pruebas
    return false
}

const Negativo = (valor) =>{
    if(valor < 0)return true

    //si pasa todas las pruebas
    return false
}
const Entero = (valor = 0) => Math.abs(valor)
const Decimales = (valor = 0,decimales=0) => valor.toFixed(decimales)

const GetUserURL = (funtion,parametro=null,datos={}) =>{
    switch(funtion){
      case "verifyUser":return `/test/?function=verifyUser&username=${parametro}&email=${parametro}`;
    }
  }
export default Test;
