import React from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import {Contexto} from '../../../pages/test/Test'

//HELPERS INTERNOS
//muestra los errores en consola
export const Error = (error) =>{
    return console.error(error)
}
//muestra datos en consola
export const Consola = (data) =>{
    return console.log(data)
}
//crea un intervalo pasandole la funcion y tiempo en segundos
export const Intervalo = (_function,_time) => {
    setInterval(_function, _time);
}
export const Loading = () =>{
    return (<><div>CARGANDO</div></>)
}
export const Icon = (icon,clase) =>{
    return new Helpers().Icon(icon,clase)
}
