import React, { useState,useRef, useEffect } from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import {Contexto} from '../../../pages/test/Test'
import {Error,Icon,Intervalo,Consola,Loading} from './Helpers'
import { isMobile } from "react-device-detect";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
//este componente necesita de una funcion para setear el favorito
//este favorito podria referirse a el id del elemento
export const WalletPop = ({tkn}) =>{
    //las props ctx refieren al contexto y el tkn refiere al token cargado
    const ref_input_pop = useRef()
    const [editable,setEditable] = useState(false)
    const [expanded,setExpanded] = useState(false)
    const {
        id,name,sufijo,cantidad,ath_price,ath_date,
        logo,grafica_7d,current_price,rank_id
    } = tkn
    const CurrentYear = new Date().getFullYear()
    const tooltipstyle = { 
        backgroundColor: "green", 
        color: "#fff",
        width:"150px",
        minWidth:"150px",
        maxWidth:"50vw",
        zIndex:10000,
        fontSize:"14px",
        borderRadius:"10px",
        opacity:1,
        textTransform:"none",
        fontWeigth:600 
    }
    return (
    <Contexto.Consumer>
        {({TypePrice,IsLogged,BorrarPop,onChangeValuePop})=>{
            const handlerEdit = () =>{
                if(editable){
                    //save
                    onChangeValuePop(id,ref_input_pop.current.value)
                } else {
                    //make editable
                }
                setEditable(!editable)
            }
            const MobileVersion = ()=>{
                if(!isMobile) return
                return (<>
                    <div  className={"mwp-mobile"}>
                        <div className={"div-wallet-star mobile-section-1 "} >
                            <button className="favorite btn-star" >
                                <p className="fa fa-star">
                                    <span >{rank_id}</span>
                                </p> 
                            </button> 
                                <div className="div-wallet-crypto  border-left-2">
                                    <div className="div-names-crypto">
                                        <div className="cripto-logo-coin">
                                            <img  src={logo} width={30} height={30} />
                                        </div>
                                        <div className="cripto-names">
                                            <div className="cripto-name"> <b><span>{name} / </span></b></div>
                                        </div>
                                        <div className="cripto-names">
                                            <div className="cripto-sufijo">{sufijo}</div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className={"mobile-section-2 "}>
                            <div className="div-wallet-crypto expanded  border-left-2" >
                                <span>Tokens</span>
                                <div className="div-names-crypto" >
                                    <input 
                                        className="input-tokens-wallet"
                                        ref={ref_input_pop}
                                        type={"number"}
                                        defaultValue={cantidad}
                                        disabled={!editable}
                                        placeholder={0.0000}
                                        style={{maxHeight:"25px",width:"100%",borderRadius:"5px"}}
                                        >
                                    </input>
                                    <div 
                                        className={"edit-button fa "+(!editable?"fa-pencil":"fa-save")} 
                                        onClick={handlerEdit}>
                                    </div>
                                </div>
                            </div>
                            <div className="div-wallet-current expanded border-left-2">
                                <span className="title-mobile-current">{TypePrice=="ATH"?"Value in ATH":"Current Value"}</span>
                                <span>${DollarFormat(TypePrice=="ATH"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }</span>
                            </div>
                            
                        </div>
                    </div>
                </>)
            }
            const DesktopVersion = () =>{
                if(isMobile)return

                return (<>
                
                    <div  className="comp-my-wallet">
                        <div className="div-wallet-star">
                            <span>{rank_id}</span>
                        </div>
                        <div className="div-wallet-crypto  border-left-2">
                            <div className="cripto-logo-coin">
                                <img  src={logo} width={30} height={30} />
                            </div>
                            <div className="cripto-names">
                                <div className="cripto-name"> <b><span>{name} /</span></b></div>
                            </div>
                            <div className="cripto-names">
                                <div className="cripto-sufijo">{sufijo}</div>
                            </div>
                            {
                                ath_date?.includes(CurrentYear.toString()) 
                                && 
                                <>
                                    <img 
                                        src={"/assets/img/DiamontJustHodl_1.png"} 
                                        width={30} 
                                        height={30} 
                                        data-tooltip-id={"my-tooltip-ath-" + sufijo}
                                        data-tooltip-content={`New ATH in ${CurrentYear}`}
                                        data-tooltip-place="bottom"
                                    />
                                    <Tooltip  id={"my-tooltip-ath-" + sufijo}   style={tooltipstyle} />
                                 </>
                            }
                        </div>
                        <div  className="div-wallet-tokens  border-left-2">                      
                            
                            <input 
                                className="input-tokens-wallet"
                                ref={ref_input_pop}
                                type={"number"}
                                defaultValue={cantidad}
                                disabled={!editable}
                                placeholder={0.0000}
                                >
                            </input>
                            <div 
                                className={"edit-button fa "+(!editable?"fa-pencil":"fa-save")} 
                                onClick={handlerEdit}>
                            </div>
                        </div>
                        <div className="div-wallet-ath  border-left-2">
                            <span>${DollarFormat(ath_price)}</span>
                        </div>
                        <div className="div-wallet-high  border-left-2">
                            <b> {ToX((((ath_price*100) / current_price )),0)}</b>
                        </div>
                        <div className="div-wallet-total  border-left-2">
                            ${DollarFormat(TypePrice=="ATH"?(cantidad * ath_price || 0):(cantidad * current_price  || 0)) }
                        </div>
                        <div className="div-wallet-del  border-left">
                            
                            <button 
                            className="buton-del" 
                            data-index={id}
                            onClick={BorrarPop}>
                            <i className="fa fa-trash" data-index={id}></i>
                            </button>
                            
                        </div>
                    </div>
                </>)
            }







            return (<>
                <DesktopVersion />
                <MobileVersion />
            </>)
        }}
    </Contexto.Consumer>)
}
