import React, { useState,useRef, useEffect } from "react";
import {DollarFormat,TokenFormat, ToX,Helpers} from '../../../helpers'
import {faSave,  faTrash,faStar,faPencil} from "@fortawesome/free-solid-svg-icons";
import {Contexto} from '../../../pages/test/Test'

//aqui iniicia el nuevo componente
export const HeadAthMyWallet= (side,priceType) =>{
    if(side==1){
        return (<>
            <div className="comp-athRow-title ath-user mobile-name">
                
                
                <div className="div-criptoData div-center ">
                    <span>CRYPTO</span>
                </div>
            </div>
        </>);
    } else if(side==2){
        return (<>
            <div className="comp-athRow-title ath-user mobile-data">
                <div className="div-currentPrice div-center">
                    <span>CURRENT PRICE</span>
                </div>
                <div className="div-currentPrice div-center">
                    <span>TOKENS</span>
                </div>
                
                <div className="div-athPrice div-center">
                    <span>ATH</span>
                </div>
                <div className="div-dropPorcent div-center">
                    <span>TO HIGH</span>
                </div>
                <div className="div-dataAth div-center">
                    <span>DATE ATH</span>
                </div>
                <div className="div-dropPorcent div-center">
                    <span>PRICE DROP ATH</span>
                </div>
                <div className="div-volume24 div-center">
                    {priceType=='current'?<span>VALUE USD</span>:<span>VALUE ATH</span>}
                </div>
            </div>
        </>);
    } else {
        return (<>
            <div className="comp-wallet-title">
                <div className="div-title-star">
                    {new Helpers().Icon(faStar,'star-icon-2')}
                </div>
                <div className="div-title-crypto border-left">
                    <span>CRYPTO</span>
                </div>
                <div className="div-title-current border-left">
                    <span>CURRENT PRICE</span>
                </div>
                <div className="div-title-tokens border-left">
                    <span>TOKENS</span>
                </div>
                <div className="div-title-ath border-left">
                    <span>ATH</span>
                </div>
                <div className="div-title-high border-left">
                    <span>TO HIGH</span>
                </div>
                <div className="div-title-date border-left">
                    <span>DATE ATH</span>
                </div>
                <div className="div-title-drop border-left">
                    <span>PRICE DROP ATH</span>
                </div>
                <div className="div-title-total border-left">
                    {priceType=='current'?<span>CURRENT VALUE</span>:<span>VALUE ATH</span>}
                </div>
                <div className="div-title-del border-left">
                    <div className="del-button">
                       
                    </div>
                </div>
            </div>
        </>);
    }
    
}
export const HeadAthMyWalletPop= (side,priceType) =>{
    
    return (<>
        <div className="comp-wallet-title pop-title">
            <div className="div-title-star">
                #
            </div>
            <div className="div-title-crypto border-left">
                <span>CRYPTO</span>
            </div>
            <div className="div-title-tokens border-left">
                <span>TOKENS</span>
            </div>
            <div className="div-title-current border-left">
                {priceType=='current'?<span>CURRENT PRICE</span>:<span>ATH PRICE</span>}
            </div>
            <div className="div-title-total border-left">
                {<span>TO HIGH</span>}
            </div>
            <div className="div-title-total border-left">
                {priceType=='current'?<span>TOTAL IN CURRENT PRICE</span>:<span>TOTAL VALUE IN ATH</span>}
            </div>
            <div className="div-title-del border-left">
                <div className="del-button">
                    
                </div>
            </div>
        </div>
    </>);
    
}
