import React, { useEffect, useState } from "react";
import './header.css';
import './header_responsive.css';
import { Link } from 'react-router-dom';
import { SiteLogo } from '../../components';
import { faChartLine, faCalculator, faWallet, faBell, faChartSimple, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select  from 'react-select';
import { isMobile } from "react-device-detect";
//New ATH in 2023
//confirm(
function Header({Contexto,home,login,menu,register,noBeta}) {
    const [stiky, setStiky] = useState(false);
    const [scrollY, setScrollY] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll',handlerScroll);
        return ()=>window.removeEventListener('scroll',handlerScroll);
    }, []);
    const handlerScroll = () =>{
        setScrollY(window.scrollY)
         if (scrollY >= 100) {
             setStiky(true);
         } else {
             setStiky(false);
         }
     }
     const handleClickScroll = async (section,position,aumentar) => {
        const element = document.getElementById(section);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          //element.scrollIntoView({ behavior: 'smooth' });
         await element.scrollIntoView({block: position, behavior: 'smooth'});
        }
        let tiempo = null
        //console.log(window.scrollY)
        tiempo = setTimeout(()=>{
            window.scroll({
                top: window.scrollY - aumentar,
                left: 0,
                behavior: "smooth",
              })
              clearTimeout(tiempo)
        },500)
      };
     
    return (<Contexto.Consumer>
        {(contexto)=>{
            const {isBeta,onCloseSession,globalSession,isLogged,C,handlerDarkMode,dark} = contexto
            const Menu = ()=>{
                return (
                    <nav>
                        <Link 
                            key={'#comp-calculator'} 
                            className="main-menu-link link" 
                            onClick={() => handleClickScroll("root","start",100)}
                            >
                            
                            <FontAwesomeIcon icon={faCalculator} />
                            <span>Crypto Calculator ATH</span>
                            
                        </Link>
                        <Link 
                            key={'#comp-wallet'} 
                            className="main-menu-link link" 
                            onClick={() => handleClickScroll("comp-wallet","start",100)}
                            >
                            <FontAwesomeIcon icon={faWallet} />
                            <span>My Wallet ATH</span>
                        </Link>
                        <Link 
                            key={'#comp-my-wallet-finish'} 
                            className="main-menu-link link" 
                            onClick={() => handleClickScroll("comp-swap","start",100)}
                            >
                            <FontAwesomeIcon icon={faArrowsRotate} />
                            <span>Swap ATH</span>
                        </Link>
                        <Link 
                            key={'#comp-since-ath'} 
                            className="main-menu-link link" 
                            onClick={() => handleClickScroll("comp-since-ath","start",100)}
                        >
                            <FontAwesomeIcon icon={faChartSimple} />
                            <span>Ranking ATH</span>
                        </Link>
                    </nav>
                );
            }
            const User = ()=>{
                const {username} = globalSession
                return(
                    <div className="div-right-header in-home">
                        {!isMobile && <FontAwesomeIcon className="icon-notification" icon={faBell} />}
                        <div className="div-login" >
                            <Link key={'link004'} className="login-menu-link" 
                            onClick={onCloseSession} >
                                <div className="img-login">
                                    <span>{username[0]}</span>
                                </div>
                                {!isMobile && <span className="name-login">{username}</span>}
                                
                            </Link>
                            <span className="link-separator"></span>
                            <Link key={'link005'} className="link-lang" >
                                <span onClick={onCloseSession} style={{color:"white"}} className="select-lang">Logout</span>
                                
                            </Link>
                        </div>
                    </div>
                )
            }
            const Main = ()=>{
                return(
                    <div className="div-right-header in-login">
                        <div className="div-login">
                        <Link key={'link004'} className="main-menu-link link-login" to="/login"  >
                                <span>LOGIN</span>
                            </Link>
                            <span className="link-separator"></span>
                            <Link key={'link005'} className="main-menu-link link-register" to="/register"  >
                                <span>REGISTER</span>
                            </Link>
                        </div>
                    </div>
                )
            }
            const Login = ()=>{
                return(
                    <div className="div-right-header in-login">
                        <div className="div-login">
                        <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                                <span>BACK TO HOME</span>
                            </Link>
                            <span className="link-separator"></span>
                            <Link key={'link005'} className="main-menu-link link-register" to="/register"  >
                                <span>REGISTER</span>
                            </Link>
                        </div>
                    </div>
                )
            }
            const Register = ()=>{
                return(
                    <div className="div-right-header in-register">
                        <div className="div-login">
                            <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                                <span>BACK TO HOME</span>
                            </Link>
                            <span className="link-separator"></span>
                            <Link key={'link005'} className="main-menu-link link-login" to="/login"  >
                                <span>LOGIN</span>
                            </Link>
                        </div>
                    </div>
                )
            }
            const Beta = () =>{
                return (<>{!noBeta &&  
                <div className="beta">
                    <span>Beta Version</span>
                </div>}
            
            </> )
            }
            const DarkMode = () =>{
                return(<div 
                    className={!dark?"mode dark":"mode light"}
                    onClick={handlerDarkMode}
                >
                    <span>{!dark?"1":"2"}</span>
                </div>)
            }
            return (<>
                <div  className={!dark?"comp-header Dark":"comp-header Light"} >
                    <SiteLogo logo={C.SITE_LOGO_WHITE}  height={50}/>
                    {menu && !isMobile && <Menu />}
                    {isLogged && <User />}
                    {!isLogged && home && <Main />}
                    {!isLogged && login && <Login />}
                    {!isLogged && register &&<Register />}
                </div>
                {isBeta && <Beta />}
                
            </>)
        }}
    </Contexto.Consumer>)
  }
  
  export default Header;