import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
import './sitelogo.css';
import {Loader} from '../../components';

function SiteLogo({logo,dark,height}) {
    const [user, setUser] = useState();
    return (
        <div className={"comp-site-logo"}>
            <img src={logo}  height={height}/>
        </div>
      
    );
  }
  
  export default SiteLogo;